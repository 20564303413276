import _ from 'lodash'
import moment from 'moment'

import { getMonthlySnapshot, IBalanceSnapshot } from '../models'

export interface ICashFlowEvent {
    amount: number
    date: Date
}

const daysBetween = (date1: Date, date2: Date) => {
    const oneDay = 24 * 60 * 60 * 1000
    return Math.round(Math.abs((date1.getTime() - date2.getTime()) / oneDay))
}

const xnpv = (rate: number, values: ICashFlowEvent[]) => {
    let xnpv = 0.0
    const firstDate = new Date(values[0].date)
    for (const key in values) {
        const tmp = values[key]
        const value = tmp.amount
        const date = new Date(tmp.date)
        xnpv += value / Math.pow(1 + rate, daysBetween(firstDate, date) / 365)
    }
    return xnpv
}

const xirr = (values: ICashFlowEvent[], guess: number) => {
    if (!guess) {
        guess = 0.1
    }

    let x1 = 0.0
    let x2 = guess
    let f1 = xnpv(x1, values)
    let f2 = xnpv(x2, values)
    for (let i = 0; i < 100; i++) {
        if (f1 * f2 < 0.0) {
            break
        }
        if (Math.abs(f1) < Math.abs(f2)) {
            f1 = xnpv((x1 += 1.6 * (x1 - x2)), values)
        } else {
            f2 = xnpv((x2 += 1.6 * (x2 - x1)), values)
        }
    }

    if (f1 * f2 > 0.0) {
        return null
    }

    const f = xnpv(x1, values)
    let rtb: number
    let dx: number
    if (f < 0.0) {
        rtb = x1
        dx = x2 - x1
    } else {
        rtb = x2
        dx = x1 - x2
    }
    for (let j = 0; j < 1000; j++) {
        dx *= 0.5
        const xMid = rtb + dx
        const fMid = xnpv(xMid, values)
        if (fMid <= 0.0) rtb = xMid
        if (Math.abs(fMid) < 1.0e-6 || Math.abs(dx) < 1.0e-6) return xMid
    }
    return null
}

const simpleReturn = (fromValue: number | null, toValue: number): number | null => {
    if (!fromValue) {
        return null
    }

    // round to cents for percent calculation
    fromValue = _.round(fromValue, 2)
    toValue = _.round(toValue, 2)

    return fromValue !== 0 ? (toValue - fromValue) / fromValue : null
}

interface IPeriod {
    date: moment.Moment
    weight: number
    amount: number
}

// const mwrr = (
//     endMonth: moment.Moment,
//     endBalance: number,
//     balanceHistory: IBalanceSnapshot[],
//     startMonth: moment.Moment,
//     log?: boolean): number|null => {
//     // modified dietz method - https://breakingdownfinance.com/wp-content/uploads/2016/07/Modified-Dietz.xlsx

//     if (log) console.log('endBalance', endBalance)
//     // can't end with zero balance
//     if (!endBalance) {
//         return 0
//     }

//     let startDate = moment(startMonth).endOf('M')
//     if (log) console.log('startDate (1)', startDate.toISOString())

//     let startSnapshot = getMonthlySnapshot(balanceHistory, startDate)
//     let startBalance = startSnapshot ? startSnapshot.balance : 0
//     if (log) console.log('startBalance (1)', startBalance)
//     // can't start with zero balance, fast forward until there's a balance
//     if (!startBalance) {
//         _.each(balanceHistory, snapshot => {
//             if (snapshot.balance) {
//                 startDate = moment(`${snapshot.month}/1/${snapshot.year}`, 'M/D/YYYY').endOf('M')
//                 startBalance = snapshot.balance
//                 return false
//             }
//         })
//     }

//     if (log) console.log('startDate (2)', startDate.toISOString())
//     if (log) console.log('startBalance (2)', startBalance)

//     const endDate = moment(endMonth).endOf('M')
//     const totalDays = endDate.diff(startDate, 'd')
//     if (log) console.log('totalDays', totalDays)

//     const cashFlows: IPeriod[] = []
//     let totalCashFlows: number = 0

//     _.each(balanceHistory, snapshot => {
//         // current month
//         const date = moment(`${snapshot.month}/1/${snapshot.year}`, 'M/D/YYYY')

//         if (date.isBetween(startDate, endMonth, 'M', '(]') && snapshot.contribution) {
//             const cashFlow: IPeriod = {
//                 date: date.endOf('M'),
//                 weight: date.diff(startDate, 'd') / totalDays,
//                 amount: snapshot.contribution
//             }
//             totalCashFlows += snapshot.contribution
//             cashFlows.push(cashFlow)
//             if (log) console.log('cash flow', cashFlow)
//         }
//     })

//     const profitOrLoss = endBalance - startBalance - totalCashFlows
//     if (log) console.log('profitOrLoss', profitOrLoss, endBalance, startBalance, totalCashFlows)
//     const averageCapital = startBalance + _.sumBy(cashFlows, cashFlow => {
//         return cashFlow.amount * cashFlow.weight
//     })
//     if (log) console.log('averageCapital', averageCapital, cashFlows)
//     const rateOfReturn = profitOrLoss / averageCapital
//     if (log) console.log('rateOfReturn', rateOfReturn)
//     return rateOfReturn
// }

interface ISubPeriod {
    date: Date
    endingValue: number
    cashFlow: number
    valueAfterCashFlow: number
    hpr: number | null
}

const twrr = (
    endMonth: moment.Moment,
    endBalance: number,
    balanceHistory: IBalanceSnapshot[],
    startMonth: moment.Moment,
    log?: boolean,
): number | null => {
    // modified dietz method - https://www.fool.com/about/how-to-calculate-investment-returns/

    if (log) console.log('endBalance', endBalance)
    // can't end with zero balance
    if (!endBalance) {
        return null
    }

    const subPeriods: ISubPeriod[] = []

    let startDate = moment(startMonth).endOf('M')
    if (log) console.log('startDate (1)', startDate.toISOString())

    const startSnapshot = getMonthlySnapshot(balanceHistory, startDate)
    let startBalance = startSnapshot ? startSnapshot.balance : 0
    if (log) console.log('startBalance (1)', startBalance)
    // can't start with zero balance, fast forward until there's a balance
    if (!startBalance) {
        _.each(balanceHistory, (snapshot) => {
            if (snapshot.balance) {
                startDate = moment(
                    `${snapshot.month}/1/${snapshot.year}`,
                    'M/D/YYYY',
                ).endOf('M')
                startBalance = snapshot.balance
                return false
            }
        })
    }

    if (log) console.log('startDate (2)', startDate.toISOString())
    if (log) console.log('startBalance (2)', startBalance)

    _.each(balanceHistory, (snapshot) => {
        // current month
        const date = moment(`${snapshot.month}/1/${snapshot.year}`, 'M/D/YYYY')

        if (date.isSame(startDate, 'M')) {
            // push initial value
            const initialPeriod: ISubPeriod = {
                date: moment(date).endOf('M').toDate(),
                endingValue: snapshot.balance,
                cashFlow: 0,
                valueAfterCashFlow: snapshot.balance,
                hpr: null,
            }
            subPeriods.push(initialPeriod)
            if (log) console.log('initial period', initialPeriod)
        } else if (date.isBetween(startDate, endMonth, 'M', '(]')) {
            // push inflow/outflow
            const previousSnapshot = getMonthlySnapshot(
                balanceHistory,
                moment(date).startOf('M').add(-1, 'M'),
            )
            // const lastSubPeriod = subPeriods[subPeriods.length - 2]
            const cashFlow = snapshot.contribution || 0
            let balance = snapshot.balance
            if (date.isSame(endMonth, 'M')) {
                balance = endBalance
            }
            const endingValue = balance - cashFlow
            let hpr: number | null = null
            if (previousSnapshot) {
                hpr = endingValue / previousSnapshot.balance - 1
            }
            const additionalPeriod: ISubPeriod = {
                date: moment(date).endOf('M').toDate(),
                endingValue,
                cashFlow,
                valueAfterCashFlow: balance,
                hpr,
            }
            subPeriods.push(additionalPeriod)
            if (log) console.log('additional period', additionalPeriod)
        }
    })

    let rateOfReturn = 1
    if (log) console.log('rateOfReturn (start)', rateOfReturn)
    _.each(subPeriods, (subPeriod) => {
        if (!_.isNil(subPeriod.hpr)) {
            rateOfReturn *= 1 + subPeriod.hpr
            if (log) console.log('rateOfReturn * ', 1 + subPeriod.hpr, rateOfReturn)
        }
    })

    if (log) console.log('rateOfReturn (end)', rateOfReturn - 1)
    return rateOfReturn - 1
}

export {
    twrr as investmentReturn,
    simpleReturn,
    xirr,
    // mwrr as investmentReturn,
    xnpv,
}
