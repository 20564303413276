import { Icon } from '@blueprintjs/core'
import _ from 'lodash'
import numeral from 'numeral'
import React from 'react'

import { IConfig } from '../../models'

interface IPercentValueProps {
    config: IConfig
    value: number | null
    className?: string
    isLiability?: boolean
}

const PercentValue: React.FC<IPercentValueProps> = (props) => {
    const { config, value, className, isLiability } = props
    const roundedValue = _.round(value || 0, 4)

    if (!value || !roundedValue) {
        return (
            <div className={'value percent zero'}>
                {_.isNil(value) ? '' : config.zeroPercentValue}
            </div>
        )
    }
    const formattedValue = `${numeral(value || 0)
        .format(config.percentFormat)
        .replace(/-/g, '')}`
    return (
        <div
            className={`value percent ${
                !value
                    ? 'zero'
                    : value < 0
                    ? !isLiability
                        ? 'negative'
                        : 'positive'
                    : !isLiability
                    ? 'positive'
                    : 'negative'
            } ${className}`}
        >
            {value > 0 && <Icon className="icon" iconSize={15} icon="caret-up" />}
            {value < 0 && <Icon className="icon" iconSize={15} icon="caret-down" />}
            {formattedValue}
        </div>
    )
}

export default PercentValue
