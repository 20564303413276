import moment from 'moment'
import React from 'react'

import { IConfig } from '../../models'

interface IMonthsRowProps {
    config: IConfig
    label?: string
}

const MonthsRow: React.FC<IMonthsRowProps> = (props) => {
    const { config, label } = props

    return (
        <tr className="months-row">
            <td>{label}</td>
            {/* <td>Current</td> */}
            {config.months.map((month) => (
                <td
                    key={month.format(config.monthFormat)}
                    className={month.isSame(moment(), 'M') ? 'current' : ''}
                >
                    {month.format(config.monthFormat)}
                </td>
            ))}
        </tr>
    )
}

export default MonthsRow
