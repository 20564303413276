import { PieDatumWithColor, ResponsivePie } from '@nivo/pie'
import _ from 'lodash'
import numeral from 'numeral'
import React from 'react'

import { getHoldingColor } from '../../lib/colors'
import { IAccountsHistory, IConfig, ICurrentHoldings, ICurrentQuotes } from '../../models'

export enum HoldingsPieType {
    BySymbol,
    ByAllocation,
    ByEquityStyle,
    byEquityStyleDetail,
}

interface IPieProps {
    history: IAccountsHistory
    quotes: ICurrentQuotes
    holdings: ICurrentHoldings
    config: IConfig
    type: HoldingsPieType
}

const bySymbol = (
    history: IAccountsHistory,
    currentHoldings: ICurrentHoldings,
    quotes: ICurrentQuotes,
): PieDatumWithColor[] => {
    return _.chain(history.accounts)
        .filter((account) => {
            return !!account.account.holdings && !!account.account.holdings.length
        })
        .map((account) => {
            return account.account.holdings!
        })
        .flatten()
        .groupBy((holding) => {
            return holding.symbol
        })
        .map((holdings, symbol) => {
            const currentQuote = _.find(quotes.quotes, (q) => q.symbol === symbol)
            const currentValue = currentQuote ? currentQuote.price : 0
            const holdingColor = currentHoldings.holdings.find((h) => h.symbol === symbol)
                ? currentHoldings.holdings.find((h) => h.symbol === symbol)!.color
                : undefined
            return {
                id: symbol,
                label: symbol,
                value: _.sumBy(holdings, (h) => {
                    return h.shares * currentValue
                }),
                color: holdingColor || getHoldingColor(symbol),
            } as PieDatumWithColor
        })
        .orderBy(
            (group) => {
                return group.value
            },
            ['desc'],
        )
        .value()
}

const byAllocation = (
    history: IAccountsHistory,
    holdings: ICurrentHoldings,
    quotes: ICurrentQuotes,
): PieDatumWithColor[] => {
    return _.chain(history.accounts)
        .filter((account) => {
            return !!account.account.holdings && !!account.account.holdings.length
        })
        .map((account) => {
            return account.account.holdings!
        })
        .flatten()
        .transform(
            (result, holding) => {
                const h = _.find(holdings.holdings, (h) => h.symbol === holding.symbol)!
                const currentQuote = _.find(
                    quotes.quotes,
                    (q) => q.symbol === holding.symbol,
                )
                const currentValue = currentQuote
                    ? currentQuote.price * holding.shares
                    : 0
                result['Domestic'] += h.assetAllocation.usEquity * currentValue
                result['International'] +=
                    h.assetAllocation.internationalEquity * currentValue
                result['Fixed Income'] += h.assetAllocation.fixedIncome * currentValue
                result['Cash'] += h.assetAllocation.cash * currentValue
                result['Other'] += h.assetAllocation.other * currentValue
                // console.log('result', result)
            },
            {
                Domestic: 0,
                International: 0,
                'Fixed Income': 0,
                Cash: 0,
                Other: 0,
            },
        )
        .map((value: number, type: string) => {
            // console.log(value, type)
            return {
                id: type,
                label: type,
                value,
                color: getHoldingColor(type),
            } as PieDatumWithColor
        })
        .orderBy(
            (group) => {
                return group.value
            },
            ['desc'],
        )
        .value()
}

const byStyle = (
    history: IAccountsHistory,
    holdings: ICurrentHoldings,
    quotes: ICurrentQuotes,
): PieDatumWithColor[] => {
    return _.chain(history.accounts)
        .filter((account) => {
            return !!account.account.holdings && !!account.account.holdings.length
        })
        .map((account) => {
            return account.account.holdings!
        })
        .flatten()
        .transform(
            (result, holding) => {
                const h = _.find(holdings.holdings, (h) => h.symbol === holding.symbol)!
                const currentQuote = _.find(
                    quotes.quotes,
                    (q) => q.symbol === holding.symbol,
                )
                const currentValue = currentQuote
                    ? currentQuote.price * holding.shares * h.assetAllocation.usEquity
                    : 0
                result['Large'] +=
                    (h.equityStyle.largeCap.value +
                        h.equityStyle.largeCap.blend +
                        h.equityStyle.largeCap.growth) *
                    currentValue
                result['Mid'] +=
                    (h.equityStyle.midCap.value +
                        h.equityStyle.midCap.blend +
                        h.equityStyle.midCap.growth) *
                    currentValue
                result['Small'] +=
                    (h.equityStyle.smallCap.value +
                        h.equityStyle.smallCap.blend +
                        h.equityStyle.smallCap.growth) *
                    currentValue
                // console.log('result', result)
            },
            {
                Large: 0,
                Mid: 0,
                Small: 0,
            },
        )
        .map((value: number, type: string) => {
            // console.log(value, type)
            return {
                id: type,
                label: type,
                value,
                color: getHoldingColor(type),
            } as PieDatumWithColor
        })
        .orderBy(
            (group) => {
                return group.value
            },
            ['desc'],
        )
        .value()
}

const byStyleDetail = (
    history: IAccountsHistory,
    holdings: ICurrentHoldings,
    quotes: ICurrentQuotes,
): PieDatumWithColor[] => {
    return _.chain(history.accounts)
        .filter((account) => {
            return !!account.account.holdings && !!account.account.holdings.length
        })
        .map((account) => {
            return account.account.holdings!
        })
        .flatten()
        .transform(
            (result, holding) => {
                const h = _.find(holdings.holdings, (h) => h.symbol === holding.symbol)!
                const currentQuote = _.find(
                    quotes.quotes,
                    (q) => q.symbol === holding.symbol,
                )
                const currentValue = currentQuote
                    ? currentQuote.price * holding.shares * h.assetAllocation.usEquity
                    : 0
                result['Large Value'] += h.equityStyle.largeCap.value * currentValue
                result['Large Blend'] += h.equityStyle.largeCap.blend * currentValue
                result['Large Growth'] += h.equityStyle.largeCap.growth * currentValue
                result['Mid Value'] += h.equityStyle.midCap.value * currentValue
                result['Mid Blend'] += h.equityStyle.midCap.blend * currentValue
                result['Mid Growth'] += h.equityStyle.midCap.growth * currentValue
                result['Small Value'] += h.equityStyle.smallCap.value * currentValue
                result['Small Blend'] += h.equityStyle.smallCap.blend * currentValue
                result['Small Growth'] += h.equityStyle.smallCap.growth * currentValue
                // console.log('result', result)
            },
            {
                'Large Value': 0,
                'Large Blend': 0,
                'Large Growth': 0,
                'Mid Value': 0,
                'Mid Blend': 0,
                'Mid Growth': 0,
                'Small Value': 0,
                'Small Blend': 0,
                'Small Growth': 0,
            },
        )
        .map((value: number, type: string) => {
            // console.log(value, type)
            return {
                id: type,
                label: type,
                value,
                color: getHoldingColor(type),
            } as PieDatumWithColor
        })
        .orderBy(
            (group) => {
                return group.value
            },
            ['desc'],
        )
        .value()
}

const HoldingsPie: React.FC<IPieProps> = ({
    history,
    holdings,
    quotes,
    config,
    type,
}) => {
    let data: PieDatumWithColor[] = []
    switch (type) {
        case HoldingsPieType.BySymbol:
            data = bySymbol(history, holdings, quotes)
            break
        case HoldingsPieType.ByAllocation:
            data = byAllocation(history, holdings, quotes)
            break
        case HoldingsPieType.ByEquityStyle:
            data = byStyle(history, holdings, quotes)
            break
        case HoldingsPieType.byEquityStyleDetail:
            data = byStyleDetail(history, holdings, quotes)
            break
    }
    const totalValue = _.sumBy(data, (d) => d.value)

    return (
        <ResponsivePie
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            innerRadius={0.4}
            padAngle={0.2}
            cornerRadius={0}
            colors={(data) => {
                return _.toString(data.color)
            }}
            borderWidth={0}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            radialLabelsSkipAngle={9}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#ffffffdd"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={10}
            radialLabelsLinkHorizontalLength={12}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsSkipAngle={25}
            slicesLabelsTextColor="#ffffffdd"
            animate={true}
            // sortByValue={true}
            motionStiffness={90}
            motionDamping={15}
            sliceLabel={(label) => {
                return numeral(label.value).format(config.currencyFormat)
            }}
            theme={{
                labels: {
                    text: {
                        fontSize: 14,
                    },
                },
                tooltip: {
                    container: {
                        backgroundColor: '#111111dd',
                        padding: 0,
                    },
                },
            }}
            tooltip={({ color, id, value }) => {
                const amount = numeral(value).format(config.currencyFormat)
                const percentTotal = numeral(value / totalValue).format(
                    config.percentFormat,
                )
                return (
                    <div
                        style={{
                            backgroundColor: '#111111dd',
                            fontSize: 16,
                            color,
                            padding: 14,
                        }}
                    >
                        <div
                            style={{
                                fontSize: 14,
                                fontWeight: 700,
                                color: 'white',
                                opacity: 0.55,
                            }}
                        >
                            {id}
                        </div>
                        <div style={{ fontSize: 27, fontWeight: 400 }}>{amount}</div>
                        <div style={{ fontSize: 15, fontWeight: 400, color: 'white' }}>
                            {percentTotal}
                        </div>
                    </div>
                )
            }}
            tooltipFormat={(value) => {
                return numeral(value).format(config.currencyFormat)
            }}
        />
    )
}

export default HoldingsPie
