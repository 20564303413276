import { H3 } from '@blueprintjs/core'
import React from 'react'
import styled from 'styled-components'

const ErrorWrapper = styled.div`
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

interface IProps {
    message?: string
}

const Error: React.FC<IProps> = (props) => {
    const { message } = props

    return (
        <ErrorWrapper>
            <H3>{message}</H3>
        </ErrorWrapper>
    )
}

export default Error
