import numeral from 'numeral'
import React, { TdHTMLAttributes } from 'react'
import styled from 'styled-components'

export interface IPercentProps extends TdHTMLAttributes<HTMLTableDataCellElement> {
    value?: number | null
    format?: string | null
}

export const PercentComponent: React.FC<IPercentProps> = (props) => {
    const { value, format, className, ...rest } = props
    if (!value) {
        return (
            <td {...rest} className={`${className} nil`}>
                -
            </td>
        )
    }
    return (
        <td {...rest} className={`${className} ${!value ? 'nil' : ''}`}>
            {numeral(value).format(format || '%0.00')}
        </td>
    )
}

export const Percent = styled(PercentComponent)`
    &&& {
        color: #ffffffbb;
        &.alloc {
            color: #ffffff99;
        }
        &.nil {
            color: #ffffff11;
        }
    }
`
