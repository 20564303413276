import { H3 } from '@blueprintjs/core'
import {
    DateRangeInput,
    IDateFormatProps,
    IDateRangeShortcut,
} from '@blueprintjs/datetime'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import AccountsPie, { AccountsPieType } from '../components/Dashboard/AccountsPie'
import HoldingsPie, { HoldingsPieType } from '../components/Dashboard/HoldingsPie'
import RateOfReturnLineChart from '../components/Dashboard/RateOfReturnLineChart'
import Error from '../components/Error'
import Loading from '../components/Loading'
import { timePeriodConfigurations } from '../models'
import { colors } from '../theme'
import { MainContext } from './Main'

const DashboardWrapper = styled.div`
    height: 100%;
    margin-top: 50px;
    padding: 30px;
`

const ChartHeader = styled(H3)`
    text-align: center;
    color: #ffffff88 !important;
    padding-top: 15px;
    padding-bottom: 15px;
`

const momentFormatter = (format: string): IDateFormatProps => {
    return {
        formatDate: (date) => moment(date).format(format),
        parseDate: (str) => moment(str, format).toDate(),
        placeholder: `${format} (moment)`,
    }
}

const Dashboard: React.FC = () => {
    const context = useContext(MainContext)
    const {
        config,
        currentsLoading,
        history,
        historyLoading,
        quotes,
        quotesLoading,
        holdings,
        holdingsLoading,
    } = context

    const [lineChartStartDate, setLineChartStartDate] = useState(moment().add(-12, 'M'))
    const [lineChartEndDate, setLineChartEndDate] = useState(moment())

    // console.log('lineChartStartDate', lineChartStartDate, 'lineChartEndDate', lineChartEndDate)

    if (!config) {
        return <Loading message="Loading config..." />
    }

    if (currentsLoading || historyLoading || quotesLoading || holdingsLoading) {
        return <Loading message="Fetching data..." />
    }

    if (!history || !quotes || !holdings) {
        return <Error message="Failed to fetch data." />
    }

    const currentConfig = config!

    const pieHeight = 275

    return (
        <DashboardWrapper>
            <div style={{ textAlign: 'center', backgroundColor: colors.background }}>
                <ChartHeader>Performance Over Time</ChartHeader>
                <DateRangeInput
                    {...momentFormatter("MMM 'YY")}
                    contiguousCalendarMonths={false}
                    // initialMonth={moment().add(-1, 'y').toDate()}
                    minDate={moment('2017-12-01', 'YYYY-MM-DD').startOf('M').toDate()}
                    maxDate={moment().toDate()}
                    shortcuts={timePeriodConfigurations.map((period) => {
                        return {
                            dateRange: [
                                period.getStartDate(moment()).toDate(),
                                moment().toDate(),
                            ],
                            label: period.label,
                        } as IDateRangeShortcut
                    })}
                    onChange={(range) => {
                        const [start, end] = range
                        if (start) {
                            setLineChartStartDate(moment(start))
                        }
                        if (end) {
                            setLineChartEndDate(moment(end))
                        }
                    }}
                    value={[lineChartStartDate.toDate(), lineChartEndDate.toDate()]}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ height: 420, width: '100%' }}>
                    <RateOfReturnLineChart
                        start={lineChartStartDate}
                        end={lineChartEndDate}
                        history={history}
                        config={currentConfig}
                    />
                </div>
            </div>
            {/* <div style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
            <div style={{height: pieHeight, width: '100%'}}>
                <ChartHeader>Equity Style Tree Map</ChartHeader>
                <HoldingsTreeMap
                    history={history}
                    config={currentConfig}
                    quotes={quotes}
                    holdings={holdings} />
            </div>
        </div> */}
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                <div style={{ height: pieHeight, width: '100%' }}>
                    <ChartHeader>Holdings by Symbol</ChartHeader>
                    <HoldingsPie
                        history={history}
                        config={currentConfig}
                        quotes={quotes}
                        holdings={holdings}
                        type={HoldingsPieType.BySymbol}
                    />
                </div>
                <div style={{ height: pieHeight, width: '100%' }}>
                    <ChartHeader>Holdings by Allocation</ChartHeader>
                    <HoldingsPie
                        history={history}
                        config={currentConfig}
                        quotes={quotes}
                        holdings={holdings}
                        type={HoldingsPieType.ByAllocation}
                    />
                </div>
                <div style={{ height: pieHeight, width: '100%' }}>
                    <ChartHeader>Holdings by Style</ChartHeader>
                    <HoldingsPie
                        history={history}
                        config={currentConfig}
                        quotes={quotes}
                        holdings={holdings}
                        type={HoldingsPieType.ByEquityStyle}
                    />
                </div>
                <div style={{ height: pieHeight, width: '100%' }}>
                    <ChartHeader>Holdings by Style (Detail)</ChartHeader>
                    <HoldingsPie
                        history={history}
                        config={currentConfig}
                        quotes={quotes}
                        holdings={holdings}
                        type={HoldingsPieType.byEquityStyleDetail}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 75 }}>
                <div style={{ height: pieHeight, width: '100%' }}>
                    <ChartHeader>Assets by Account</ChartHeader>
                    <AccountsPie
                        history={history}
                        config={currentConfig}
                        type={AccountsPieType.ByAccount}
                    />
                </div>
                <div style={{ height: pieHeight, width: '100%' }}>
                    <ChartHeader>Assets by Classification</ChartHeader>
                    <AccountsPie
                        history={history}
                        config={currentConfig}
                        type={AccountsPieType.ByClassification}
                    />
                </div>
                <div style={{ height: pieHeight, width: '100%' }}>
                    <ChartHeader>Assets by Type</ChartHeader>
                    <AccountsPie
                        history={history}
                        config={currentConfig}
                        type={AccountsPieType.ByType}
                    />
                </div>
                <div style={{ height: pieHeight, width: '100%' }}>
                    <ChartHeader>Assets by Institution</ChartHeader>
                    <AccountsPie
                        history={history}
                        config={currentConfig}
                        type={AccountsPieType.ByInstitution}
                    />
                </div>
            </div>
        </DashboardWrapper>
    )
}

export default Dashboard
