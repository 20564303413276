import { HTMLTable } from '@blueprintjs/core'
import _ from 'lodash'
import numeral from 'numeral'
import React, { useContext } from 'react'
import styled from 'styled-components'

import Error from '../components/Error'
import Loading from '../components/Loading'
import CurrencyValue from '../components/NetWorthTable/CurrencyValue'
import PercentValue from '../components/NetWorthTable/PercentValue'
import { useHoldingPerformance } from '../hooks/useHoldingPerformance'
import { simpleReturn } from '../lib/finance'
import { MainContext } from './Main'

const StyledPerformanceTable = styled(HTMLTable)`
    width: 100%;

    &&& thead {
        .spacer {
            td {
                height: 24px;
            }
        }
        td {
            font-weight: 700;
        }
    }
    &&& th {
        text-align: right;
        padding: 12px 6px;
        font-size: 16px;

        &.centered {
            text-align: center;
        }
        &.left {
            text-align: left;
        }
    }
    &&& td {
        vertical-align: middle;
        font-size: 14px;
        padding: 12px 6px;
        text-align: right;

        &.centered {
            text-align: center;
        }
        &.left {
            text-align: left;
        }
        &.symbol-name {
            background-color: #ffffff11;
            font-weight: 700;
            // text-align: left;
            font-size: 20px;
        }

        .diff {
            display: flex;
            flex-direction: row;
            font-weight: 700;
            opacity: 0.9;
            font-size: 19px;

            .key {
                margin-left: 4px;
                opacity: 0.3;
            }
            .value {
                flex: 1;
                text-align: right;
                opacity: 1;

                &.zero {
                    color: ${({ theme }) => theme.colors.valueZero};
                    opacity: 0.7;
                }
                &.positive {
                    color: ${({ theme }) => theme.colors.valueGain};
                }
                &.negative {
                    color: ${({ theme }) => theme.colors.valueLoss};
                }
            }
        }

        .contributions {
            font-weight: 400;
        }

        .balance {
            color: #ffffff;
            opacity: 0.3;
            margin-top: 5px;

            &.zero {
                color: ${({ theme }) => theme.colors.valueZero};
            }
            &.negative {
                // color: ${({ theme }) => theme.colors.valueLoss};
            }

            &.with-contrib {
                .currency.value {
                    &::before {
                        color: #ffffffaa;
                        opacity: 0.75;
                        content: '+';
                    }
                }
            }

            &.non-final {
                .currency.value {
                    color: #8dc3ff;
                    opacity: 1;

                    &::before {
                        opacity: 0.75;
                        content: '± ';
                    }
                }
            }
        }
    }
`

const Performance: React.FC<{}> = () => {
    const context = useContext(MainContext)
    const { config, quotesLoading, quotes } = context

    const { loading, error, performance } = useHoldingPerformance()

    if (!config || loading || quotesLoading) {
        return <Loading message="Calculating..." />
    }

    if (!quotes || !performance || !!error) {
        return <Error message="Failed to load quote information." />
    }

    const timePeriods = [
        '1 day',
        '3 day',
        '5 day',
        '2 week',
        '1 month',
        '3 month',
        '6 month',
        '9 month',
        '1 year',
        '2 year',
        '3 year',
        '5 year',
        '10 year',
    ]

    return (
        <>
            <div style={{ marginTop: 75 }}>
                <StyledPerformanceTable bordered condensed>
                    <thead style={{ backgroundColor: '#ffffff22' }}>
                        <tr>
                            <th>Holding</th>
                            <th>Current</th>
                            {_.map(timePeriods, (periodName) => (
                                <th key={periodName}>{periodName}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(_.keys(performance), (symbol) => {
                            const currentQuote = _.find(
                                quotes.quotes,
                                (q) => q.symbol === symbol,
                            )
                            const currentValue = currentQuote ? currentQuote.price : 0
                            const currencyFormat = '0,0.00'
                            return (
                                <React.Fragment key={`holding${symbol}`}>
                                    <tr>
                                        <td className="symbol-name">{symbol}</td>
                                        <td>
                                            {numeral(currentValue).format(currencyFormat)}
                                        </td>
                                        {_.map(timePeriods, (periodName) => {
                                            const periodValue =
                                                performance[symbol][periodName]
                                            const diff = simpleReturn(
                                                periodValue,
                                                currentValue,
                                            )
                                            return (
                                                <td key={periodName}>
                                                    <div className={'diff'}>
                                                        <PercentValue
                                                            config={config}
                                                            value={diff}
                                                        />
                                                    </div>
                                                    <div
                                                        className={`balance
                                                ${
                                                    !periodValue
                                                        ? 'zero'
                                                        : periodValue < 0
                                                        ? 'negative'
                                                        : 'positive'
                                                }`}
                                                    >
                                                        <CurrencyValue
                                                            config={config}
                                                            value={periodValue}
                                                            format={currencyFormat}
                                                        />
                                                    </div>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </StyledPerformanceTable>
            </div>
        </>
    )
}

export default Performance
