import { HTMLTable } from '@blueprintjs/core'
import _ from 'lodash'
import React, { Fragment, useContext } from 'react'
import styled from 'styled-components'

import Loading from '../components/Loading'
import AccountRows from '../components/NetWorthTable/AccountRows'
import AggregateRow from '../components/NetWorthTable/AggregateRow'
import MonthsRow from '../components/NetWorthTable/MonthsRow'
import { AccountCategories, AccountCategory, IAccountHistory } from '../models'
import { MainContext } from './Main'

const StyledNetWorthTable = styled(HTMLTable)`
    margin-top: 70px;
    font-weight: 600;
    width: 100%;

    &&& thead {
        .spacer {
            td {
                height: 24px;
            }
        }
        td {
            font-weight: 700;
        }
    }
    &&& td {
        padding: 4px;
        font-size: 13px;
        text-align: right;

        .diff {
            display: flex;
            flex-direction: row;
            font-size: 11px;

            .key {
                margin-left: 4px;
                opacity: 0.3;
            }
            .value {
                flex: 1;
                text-align: right;
                opacity: 1;

                .icon {
                    margin-bottom: -2px;
                }

                &.zero {
                    color: ${({ theme }) => theme.colors.valueZero};
                    opacity: 0.7;
                }
                &.positive {
                    color: ${({ theme }) => theme.colors.valueGain};
                }
                &.negative {
                    color: ${({ theme }) => theme.colors.valueLoss};
                }
            }
        }

        .contributions {
        }

        .balance {
            color: ${({ theme }) => theme.colors.foreground};
            opacity: 0.8;

            &.zero {
                color: ${({ theme }) => theme.colors.valueZero};
            }
            &.negative {
                // color: ${({ theme }) => theme.colors.valueLoss};
            }

            &.with-contrib {
                .currency.value {
                    &::before {
                        color: ${({ theme }) => theme.colors.foregroundOpacity('aa')};
                        opacity: 0.75;
                        content: '+';
                    }
                }
            }

            &.non-final {
                .currency.value {
                    color: #8dc3ff;
                    opacity: 1;

                    &::before {
                        opacity: 0.75;
                        content: '± ';
                    }
                }
            }
        }

        &.label {
            text-align: left;

            .institution {
                flex: 1;
                color: ${({ theme }) => theme.colors.foregroundOpacity('77')};
                font-size: 11px;
            }
            .period {
                font-size: 11px;
                flex: 1;
                color: ${({ theme }) => theme.colors.foregroundOpacity('44')};
                text-align: right;

                .return-type {
                    color: #b670b6;
                }
            }
        }
    }
    &&& .heading {
        .balance {
            opacity: 1;
        }

        &.no-gain-loss {
            height: 50px;
        }
    }
    &&& tbody {
        td.current {
            background-color: ${({ theme }) =>
                theme.colors.menuBar}; //#51a3df38; //#ffffff25;
        }
        tr:last-child {
            td {
                border-bottom: 1px solid ${({ theme }) => theme.colors.menuBar};
            }
        }
    }
    &&& .net-worth-heading {
        td {
            font-weight: 700;
            background-color: ${({ theme }) => theme.colors.netWorthBackground};
            color: rgba(0, 0, 0, 0.8);

            &.current {
                .balance {
                    color: #e7fff9 !important;

                    &.with-contrib {
                        .currency.value {
                            &::before {
                                color: rgba(255, 255, 255, 0.75);
                            }
                        }
                    }
                }
                background-color: ${({ theme }) =>
                    theme.colors.netWorthCurrentBackground} !important;
            }

            &.label {
                .period {
                    color: rgba(0, 0, 0, 0.6);
                }
            }

            .diff {
                .value {
                    font-weight: 700;
                    &.positive {
                        color: #064e3b;
                    }
                    &.negative {
                        font-weight: 700;
                        color: #7f1d1d;
                    }
                }
            }

            .balance {
                color: rgba(0, 0, 0, 0.81);

                &.with-contrib {
                    .currency.value {
                        &::before {
                            color: rgba(0, 0, 0, 0.5);
                        }
                    }
                }

                &.non-final {
                    .currency.value {
                        color: #eafff9 !important;
                    }

                    &.with-contrib {
                        .currency.value {
                            &::before {
                                color: rgba(255, 255, 255, 0.55);
                            }
                        }
                    }
                }
            }
        }
    }
    &&& .category-heading {
        td {
            font-weight: 700;
            background-color: ${({ theme }) => theme.colors.categoryHeading};
        }
    }
    &&& .type-heading {
        td {
            background-color: ${({ theme }) => theme.colors.typeHeading};
        }
    }

    &&& tr.months-row {
        td {
            // background-color: ${({ theme }) => theme.colors.menuBar};
            color: ${({ theme }) => theme.colors.foregroundOpacity('55')};
        }
    }
`

const SpacerRow: React.FC<{}> = () => {
    return (
        <tr className="spacer">
            <td colSpan={99}></td>
        </tr>
    )
}

const NetWorthTable: React.FC<{}> = () => {
    const context = useContext(MainContext)
    const { config, history: historyData } = context

    if (!config) {
        return <Loading message="Loading config..." />
    }

    const history = historyData!
    const currentConfig = config!

    const getAggregateKeyValues = (accountsHistories: IAccountHistory[]): string[] => {
        return _.map(
            _.uniqBy(accountsHistories, (history) => {
                return history.account[currentConfig.selectedAggregationKey]
            }),
            (history) => {
                return history.account[currentConfig.selectedAggregationKey] || ''
            },
        )
    }

    const GetAggregateRows = (
        accountHistories: IAccountHistory[],
        isLiabilities = false,
    ): JSX.Element => {
        const keys = getAggregateKeyValues(accountHistories)

        // sort keys by total balance of their accounts
        let sortedKeys = _.sortBy(keys, (key) => {
            const aggregateAccounts = accountHistories.filter(
                (ah) => ah.account[currentConfig.selectedAggregationKey] === key,
            )
            return _.sumBy(aggregateAccounts, (accountHistory) => {
                const lastSnapshot = _.last(accountHistory.history)
                if (!lastSnapshot) {
                    return 0
                }
                return lastSnapshot.balance
            })
        })
        if (!isLiabilities) {
            sortedKeys = _.reverse(sortedKeys)
        }

        return (
            <>
                {sortedKeys.map((key) => {
                    const aggregateAccounts = accountHistories.filter(
                        (ah) => ah.account[currentConfig.selectedAggregationKey] === key,
                    )
                    if (!_.size(aggregateAccounts)) {
                        return null
                    }
                    return (
                        <Fragment key={key}>
                            <AggregateRow
                                title={key}
                                config={currentConfig}
                                accountHistories={aggregateAccounts}
                                className="type-heading"
                            />
                            <AccountRows
                                config={currentConfig}
                                accountHistories={aggregateAccounts}
                                isLiabilities={isLiabilities}
                            />
                        </Fragment>
                    )
                })}
            </>
        )
    }

    return (
        <>
            <StyledNetWorthTable interactive={false}>
                <thead>
                    <MonthsRow config={currentConfig} label="Net Worth" />
                </thead>
                <tbody>
                    <AggregateRow
                        title="Net Worth"
                        config={currentConfig}
                        accountHistories={history.accounts}
                        className="net-worth-heading"
                    />
                </tbody>
                {AccountCategories.map((category) => {
                    const accountsOfCategory = history.accounts.filter(
                        (ah) => ah.account.category === category,
                    )
                    return (
                        <Fragment key={category}>
                            <thead>
                                <SpacerRow />
                                {/* <MonthsRow config={currentConfig} label={category} /> */}
                            </thead>
                            <tbody>
                                <AggregateRow
                                    title={`All ${category}`}
                                    config={currentConfig}
                                    accountHistories={accountsOfCategory}
                                    className="category-heading"
                                />
                                {GetAggregateRows(
                                    accountsOfCategory,
                                    category === AccountCategory.Liability,
                                )}
                            </tbody>
                        </Fragment>
                    )
                })}
            </StyledNetWorthTable>
        </>
    )
}

export default NetWorthTable
