import { AccountClassification, AccountType, IAccount } from '../models/index'

const PROPERTY = '#cf75b1'

export const getHoldingColor = (label: string): string | undefined => {
    const colorMap: { [name: string]: string } = {
        // allocation
        Domestic: '#199DDB',
        'Fixed Income': '#438225',
        International: '#FABB57',
        Cash: PROPERTY,
        Other: PROPERTY,

        // equity style
        Large: '#199DDB',
        Mid: '#DA7A3F',
        Small: '#65517B',

        // equity style (detail)
        'Large Blend': '#199DDB',
        'Large Growth': '#199DDBcc',
        'Large Value': '#199DDB99',

        'Mid Blend': '#DA7A3F',
        'Mid Growth': '#DA7A3Fcc',
        'Mid Value': '#DA7A3F99',

        'Small Blend': '#65517B',
        'Small Growth': '#65517Bcc',
        'Small Value': '#65517B99',
    }
    return colorMap[label]
}

export const getAccountColor = (account: IAccount): string | undefined => {
    const colorMap: { [name: string]: string } = {
        // demo
        '104 Mountain Pass': PROPERTY,
        Taxable: '#1AA1DC',
        'Rollover IRA': '#941721',
        HSA: '#FCBC4D',
        'High Yield Savings': '#640B5F',
    }
    return colorMap[account.name]
}

export const getInstitutionColorByName = (name: string): string | undefined => {
    const colorMap: { [name: string]: string } = {
        'Merrill Edge': '#012169',
        Property: PROPERTY,
        Fidelity: '#368727',
        'Bank of America': '#dc1531',
        'Capital One': '#003d5b',
        'E*TRADE': '#6633cc',
        'US Treasury': '#3367cd',
        Chase: '#3367cd',

        // demo
        Vanguard: '#941721',
        'Ally Bank': '#640B5F',
        'Charles Schwab': '#1AA1DC',
        'Wells Fargo': '#D5212F',
        'HSA Bank': '#FCBC4D',
        UFCU: '#ED6530',
    }
    return colorMap[name]
}

export const getInstitutionColor = (account: IAccount): string | undefined => {
    return getInstitutionColorByName(account.institution)
}

export const getAccountTypeColor = (accountType: AccountType): string | undefined => {
    const colorMap: { [name: string]: string } = {
        [AccountType.Investment]: '#199DDB',
        [AccountType.Property]: PROPERTY,
        [AccountType.Cash]: '#438225',
    }
    return colorMap[accountType]
}

export const getAccountClassificationColor = (
    classification: AccountClassification,
): string | undefined => {
    const colorMap: { [name: string]: string } = {
        [AccountClassification.Depository]: '#438225',
        [AccountClassification.Retirement]: '#DA7A3F',
        [AccountClassification.Taxable]: '#199DDB',
        [AccountClassification.Property]: PROPERTY,
    }
    return colorMap[classification]
}
