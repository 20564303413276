import moment from 'moment'
import React from 'react'

import {
    AccountCategory,
    IAccountHistory,
    IConfig,
    ReturnCalculationType,
    shouldShowGainLoss,
} from '../../models'
import Balance from './Balance'

interface IAccountRowProps {
    config: IConfig
    accountHistory: IAccountHistory
}

const AccountRow: React.FC<IAccountRowProps> = (props) => {
    const { config, accountHistory } = props

    const showGainLoss = shouldShowGainLoss(accountHistory, config)
    const isSimpleReturn =
        accountHistory.account.returnType === ReturnCalculationType.Simple
    const firstPeriod = config.selectedTimePeriodLabels[0]
    const additionalPeriods = config.selectedTimePeriodLabels.slice(1)

    return (
        <tr className="account">
            <td className="label">
                <div>{accountHistory.account.name}</div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="institution">
                        {accountHistory.account.institution}
                    </div>
                    {showGainLoss && (
                        <div className="period">
                            <span className="return-type">
                                {isSimpleReturn ? '' : config.rateOfReturnLabel}
                            </span>{' '}
                            {firstPeriod}
                        </div>
                    )}
                </div>
                {showGainLoss &&
                    additionalPeriods.length &&
                    additionalPeriods.map((period, i) => (
                        <div className="period" key={i}>
                            <span className="return-type">
                                {isSimpleReturn ? '' : config.rateOfReturnLabel}
                            </span>{' '}
                            {period}
                        </div>
                    ))}
            </td>
            {config.months.map((month) => {
                return (
                    <Balance
                        key={`${month.format(config.monthFormat)}`}
                        config={config}
                        month={month}
                        isCurrent={month.isSame(moment.utc(), 'month')}
                        showGainLoss={showGainLoss}
                        isLiability={
                            accountHistory.account.category === AccountCategory.Liability
                        }
                        title={accountHistory.account.name}
                        balanceHistory={accountHistory.history}
                        returnCalculationType={accountHistory.account.returnType}
                    />
                )
            })}
        </tr>
    )
}

export default AccountRow
