import { ResponsiveLine, Serie } from '@nivo/line'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React from 'react'

import { getAccountColor } from '../../lib/colors'
import { investmentReturn } from '../../lib/finance'
import {
    AccountCategory,
    AccountType,
    getMonthlyBalance,
    IAccountHistory,
    IAccountsHistory,
    IConfig,
    ReturnCalculationType,
} from '../../models'
import { colors as themeColors } from '../../theme'

interface IProps {
    history: IAccountsHistory
    config: IConfig
    start: moment.Moment
    end: moment.Moment
}

const DATE_AXIS_FORMAT = "MMM 'YY"

// pallete generator -- http://tristen.ca/hcl-picker/#/hlc/11/1.05/154837/6F3742
// const colors = ['#1F644D','#166456','#11635E','#156265','#20606C','#2D5E70','#3B5B73','#495874','#565573','#625170','#6D4D6B']
// const colors = ['#393849','#353F4F','#304553','#2B4C56','#265257','#245857','#265E55','#2B6351','#35684D','#406D48','#4D7142'].reverse()

const getCurrentBalance = (account: IAccountHistory): number => {
    return !_.isNil(account.account.currentBalance)
        ? account.account.currentBalance
        : getMonthlyBalance(account.history, moment())
}

const getPerformanceData = (
    start: moment.Moment,
    end: moment.Moment,
    history: IAccountsHistory,
): Serie[] => {
    const dates: moment.Moment[] = [start]
    let nextDate = moment(start).add(1, 'M')
    while (nextDate.isSameOrBefore(end, 'M')) {
        dates.push(nextDate)
        nextDate = moment(nextDate).add(1, 'M')
    }

    return _.chain(history.accounts)
        .filter((account) => {
            return (
                account.account.category === AccountCategory.Asset &&
                getCurrentBalance(account) !== 0 &&
                (account.account.returnType === ReturnCalculationType.MoneyWeighted ||
                    account.account.type === AccountType.Property)
            )
        })
        .orderBy(
            (account) => {
                return getCurrentBalance(account)
            },
            ['desc'],
        )
        .map((account) => {
            return {
                id: account.account.name,
                color: account.account.color || getAccountColor(account.account),
                data: _.map(dates, (date) => {
                    return {
                        x: date.format(DATE_AXIS_FORMAT),
                        y: investmentReturn(
                            date,
                            getMonthlyBalance(account.history, date),
                            account.history,
                            start,
                        ),
                    }
                }),
            } as Serie
        })
        .value()
}

const RateOfReturnLineChart: React.FC<IProps> = ({ history, config, start, end }) => {
    const data: Serie[] = getPerformanceData(start, end, history).reverse()

    const getMonthBalance = (nameValue: string, monthValue: string): string => {
        const month = moment(monthValue, DATE_AXIS_FORMAT)
        const account = _.find(history.accounts, (account) => {
            return account.account.name === nameValue
        })
        if (account) {
            return numeral(getMonthlyBalance(account.history, month)).format(
                config.currencyFormat,
            )
        }
        return '0'
    }

    const crosshairTheme: any = {
        crosshair: {
            line: {
                stroke: '#ffffffcc',
                strokeDasharray: '4 2',
            },
        },
    }
    return (
        <ResponsiveLine
            data={data}
            margin={{ top: 30, right: 190, bottom: 60, left: 90 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: false,
                reverse: false,
            }}
            yFormat={(value) => {
                return numeral(value).format(config.percentFormat)
            }}
            curve="natural"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                // legend: 'Period',
                legendOffset: 36,
                legendPosition: 'middle',
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Return',
                legendOffset: -60,
                legendPosition: 'middle',
                format: (value) => {
                    return numeral(value).format(config.percentFormat)
                },
            }}
            colors={(data) => {
                return _.toString(data.color)
            }}
            pointSize={9}
            // pointColor={{ theme: 'background' }}
            pointBorderWidth={3}
            pointBorderColor={{ theme: 'background' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            tooltip={({ point }) => (
                <div
                    style={{
                        backgroundColor: '#111',
                        color: point.color,
                        padding: 10,
                        paddingRight: 40,
                    }}
                >
                    <div style={{ fontSize: 14, fontWeight: 700 }}>{point.serieId}</div>
                    <div style={{ fontSize: 12, fontWeight: 400 }}>
                        {point.data.xFormatted}
                    </div>
                    <h2 style={{ color: 'white' }}>{point.data.yFormatted}</h2>
                    <div>
                        {getMonthBalance(
                            point.serieId.toString(),
                            point.data.xFormatted.toString(),
                        )}
                    </div>
                </div>
            )}
            enableSlices="x"
            sliceTooltip={({ slice }) => {
                return (
                    <div
                        style={{
                            background: '#111111f4',
                            padding: 10,
                            // border: '1px solid #ccc',
                        }}
                    >
                        {/* <div>x: {slice.x}</div> */}
                        {_.chain(slice.points)
                            .orderBy(
                                (point) => {
                                    return point.data.y
                                },
                                ['desc'],
                            )
                            .map((point, index) => (
                                <div key={point.id}>
                                    {!index ? (
                                        <div
                                            style={{
                                                fontSize: 20,
                                                textAlign: 'right',
                                                marginBottom: 10,
                                            }}
                                        >
                                            {start.format(DATE_AXIS_FORMAT)} -{' '}
                                            {point.data.xFormatted}
                                        </div>
                                    ) : null}
                                    <div
                                        style={{
                                            fontSize: 16,
                                            color: point.serieColor,
                                            padding: '3px 0',
                                        }}
                                    >
                                        <strong style={{ paddingRight: 30 }}>
                                            {point.serieId}
                                        </strong>{' '}
                                        <span style={{ float: 'right' }}>
                                            {point.data.yFormatted}
                                        </span>
                                    </div>
                                </div>
                            ))
                            .value()}
                    </div>
                )
            }}
            theme={{
                ...crosshairTheme,
                background: themeColors.background,
                labels: {
                    text: {
                        fontSize: 13,
                        // color: '#ffffffbb'
                    },
                },
                legends: {
                    text: {
                        fontSize: 13,
                        // color: '#ffffffbb'
                    },
                },
                grid: {
                    line: {
                        stroke: '#ffffff22',
                    },
                },
                markers: {
                    // lineColor: '#666'
                    // textColor: '#ffffffbb'
                },
                axis: {
                    domain: {
                        line: {
                            // stroke: '#ffffffbb'
                        },
                    },
                    legend: {
                        text: {
                            fill: '#ffffff',
                        },
                    },
                    ticks: {
                        line: {
                            // stroke: '#ffffffbb'
                        },
                        text: {
                            fill: '#ffffffbb',
                        },
                    },
                },
                tooltip: {
                    container: {
                        //color: 'black',
                        background: '#333',
                    },
                },
            }}
            legends={[
                {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 110,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    // itemOpacity: 0.75,
                    itemTextColor: '#ffffff66',
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#fff',
                            },
                        },
                    ],
                },
            ]}
        />
    )
}

export default RateOfReturnLineChart
