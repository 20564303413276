import { Button, Callout, Card, FormGroup, H2, InputGroup } from '@blueprintjs/core'
import firebase from 'firebase/app'
import _ from 'lodash'
import React, { SyntheticEvent, useState } from 'react'
import styled from 'styled-components'

import Loading from './Loading'

const SignInWrapper = styled.div`
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
`

const CenteredCard = styled(Card)`
    width: 350px;
`

const SignIn: React.FC = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    if (loading) {
        return <Loading message="Authenticating.." />
    }

    return (
        <SignInWrapper>
            <CenteredCard>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                    }}
                >
                    <H2 style={{ paddingBottom: 20 }}>welcome...</H2>
                    {error && (
                        <div style={{ padding: 20 }}>
                            <Callout title="Sign in error" intent="danger">
                                {_.toString(error)}
                            </Callout>
                        </div>
                    )}
                    <FormGroup label="Username">
                        <InputGroup
                            autoFocus
                            placeholder="Username"
                            value={username}
                            onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                                setUsername((e.target as HTMLInputElement).value)
                            }
                        />
                    </FormGroup>
                    <FormGroup label="Password">
                        <InputGroup
                            placeholder="Password"
                            type="password"
                            onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                                setPassword((e.target as HTMLInputElement).value)
                            }
                        />
                    </FormGroup>
                    <Button
                        type="submit"
                        style={{ marginTop: 20 }}
                        large={true}
                        onClick={() => {
                            setLoading(true)
                            firebase
                                .app()
                                .auth()
                                .signInWithEmailAndPassword(username, password)
                                .then(
                                    (credential) => {
                                        setLoading(false)
                                    },
                                    (err) => {
                                        console.error('sign in error', err)
                                        setError(err)
                                        setLoading(false)
                                    },
                                )
                        }}
                    >
                        Sign In
                    </Button>
                </form>
            </CenteredCard>
        </SignInWrapper>
    )
}

export default SignIn
