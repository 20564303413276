import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

import { FirebaseAuthConsumer, FirebaseAuthProvider } from '@react-firebase/auth'
import firebase from 'firebase/app'
import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'

import Loading from './components/Loading'
import SignIn from './components/SignIn'
import Main from './screens/Main'
import theme from './theme'

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const GlobalStyle = createGlobalStyle<any>`
    html {
        font-size: 14px;
        height: 100%;
    }

    body {
        height: 100%;
        margin: 0;
        padding: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        div#root {
            height: 100%;
        }
        background-color: ${({ theme }) => theme.colors.background};
        color: ${({ theme }) => theme.colors.foreground};
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    .bp3-portal {
        z-index: 100;
    }
    .bp3-dark table.bp3-html-table tbody tr:first-child td {
        box-shadow: none;
    }
    .bp3-popover.bp3-dark .bp3-popover-content, .bp3-dark .bp3-popover .bp3-popover-content {
        background: #0d1217;
    }
    .bp3-popover.bp3-dark .bp3-popover-arrow-fill, .bp3-dark .bp3-popover .bp3-popover-arrow-fill {
        fill: #0d1217;
    }
`

const firebaseConfig = {
    apiKey: 'AIzaSyDcaiwqbZO-Ek9MbkTdhnBCI5NFHVQ4vpc',
    authDomain: 'nw-history.firebaseapp.com',
    databaseURL: 'https://nw-history.firebaseio.com',
    projectId: 'nw-history',
    storageBucket: 'nw-history.appspot.com',
    messagingSenderId: '631383693942',
    appId: '1:631383693942:web:2eef84bdd1376253a09d57',
}

firebase.initializeApp(firebaseConfig)

const App: React.FC<{}> = () => {
    const [authBootstrapped, setAuthBootstrapped] = useState(false)

    useEffect(() => {
        return firebase.auth().onAuthStateChanged((user) => {
            console.log('onAuthStateChanged', user)
            setAuthBootstrapped(true)
        })
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
                <AppContainer className="bp3-dark">
                    <GlobalStyle />
                    <FirebaseAuthConsumer>
                        {({ isSignedIn }) => {
                            if (!authBootstrapped) {
                                return <Loading message="Loading..." />
                            }
                            if (!isSignedIn) {
                                return <SignIn />
                            }
                            return <Main />
                        }}
                    </FirebaseAuthConsumer>
                </AppContainer>
            </FirebaseAuthProvider>
        </ThemeProvider>
    )
}

export default App
