import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import {
    AccountCategory,
    getMonthlyBalance,
    getMonthlyContributions,
    getMonthlyFinal,
    IAccountHistory,
    IBalanceSnapshot,
    IConfig,
    ReturnCalculationType,
    shouldShowGainLoss,
} from '../../models'
import Balance from './Balance'

interface IAggregateRowProps {
    config: IConfig
    title: string
    accountHistories: IAccountHistory[]
    className?: string
}

const AggregateRow: React.FC<IAggregateRowProps> = (props) => {
    const { config, title, accountHistories, className } = props

    // filter to non-zero accounts when appropriate
    const filteredAccountHistories = _.filter(accountHistories, (accountHistory) => {
        const currentBalance = getMonthlyBalance(
            accountHistory.history,
            moment(config.endingDate),
        )
        return !!currentBalance || config.showZeroBalanceAccounts
    })
    // if no accounts are visible, hide the aggregate too
    if (!filteredAccountHistories.length) {
        return null
    }
    // // hide aggregates for singular accounts
    // if (!filteredAccountHistories.length || (!config.showSingularCategoryAccounts && filteredAccountHistories.length === 1)) {
    //     return null
    // }

    // const totalCurrentBalance = _.sumBy(accountHistories, accountHistory => {
    //     if (!_.isNil(accountHistory.account.currentBalance)) {
    //         return accountHistory.account.currentBalance || 0
    //     }
    //     return getMonthlyBalance(accountHistory.history, moment(config.endingDate))
    // })

    const showGainLoss = _.some(accountHistories, (ah) => {
        return shouldShowGainLoss(ah, config)
    })
    const isLiability = _.every(
        accountHistories,
        (ah) => ah.account.category === AccountCategory.Liability,
    )
    const useMoneyWeightedReturns = _.every(
        accountHistories,
        (ah) => ah.account.returnType === ReturnCalculationType.MoneyWeighted,
    )
    const returnCalculationType = useMoneyWeightedReturns
        ? ReturnCalculationType.MoneyWeighted
        : ReturnCalculationType.Simple
    const isSimpleReturn = returnCalculationType === ReturnCalculationType.Simple

    const firstPeriod = config.selectedTimePeriodLabels[0]
    const additionalPeriods = config.selectedTimePeriodLabels.slice(1)

    const balanceHistory: IBalanceSnapshot[] = _.map(config.allDataMonths, (month) => {
        return {
            month: month.month() + 1,
            year: month.year(),
            // sum of accountHistories (not filteredAccountHistories) because we want the aggregate to include the hidden accounts
            balance: _.sumBy(accountHistories, (accountHistory) =>
                getMonthlyBalance(accountHistory.history, month),
            ),
            contribution: _.sumBy(accountHistories, (accountHistory) =>
                getMonthlyContributions(accountHistory.history, month),
            ),
            final: _.every(
                accountHistories,
                (accountHistory) => !!getMonthlyFinal(accountHistory.history, month),
            ),
        }
    })
    return (
        <tr
            className={`heading aggregate ${className} ${
                !showGainLoss ? 'no-gain-loss' : null
            }`}
        >
            <td className="label">
                <div>{title}</div>
                {showGainLoss && (
                    <div className="period">
                        <span className="return-type">
                            {isSimpleReturn ? '' : config.rateOfReturnLabel}
                        </span>{' '}
                        {firstPeriod}
                    </div>
                )}
                {showGainLoss &&
                    !!additionalPeriods.length &&
                    additionalPeriods.map((period, i) => (
                        <div className="period" key={i}>
                            <span className="return-type">
                                {isSimpleReturn ? '' : config.rateOfReturnLabel}
                            </span>{' '}
                            {period}
                        </div>
                    ))}
            </td>
            {/* <Balance
            config={config}
            month={moment()}
            exactBalance={totalCurrentBalance}
            showGainLoss={showGainLoss}
            isLiability={isLiability}
            title={title}
            balanceHistory={balanceHistory}
            isCurrent={true}
            returnCalculationType={returnCalculationType}
            /> */}
            {config.months.map((month) => {
                return (
                    <Balance
                        key={`${month.format(config.monthFormat)}`}
                        config={config}
                        month={month}
                        showGainLoss={showGainLoss}
                        isLiability={isLiability}
                        title={title}
                        isCurrent={month.isSame(moment.utc(), 'month')}
                        balanceHistory={balanceHistory}
                        returnCalculationType={returnCalculationType}
                    />
                )
            })}
        </tr>
    )
}

export default AggregateRow
