import firebase from 'firebase/app'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { ICurrentQuotes } from '../models'

export const useHoldingQuotes = () => {
    const [quotes, setQuotes] = useState<ICurrentQuotes | null>(null)
    const [error, setError] = useState<any>(null)
    const [loading, setLoading] = useState(true)

    const fetchQuotes = () => {
        if (!firebase.auth().currentUser) {
            return
        }
        setLoading(true)
        const uid = firebase.auth().currentUser!.uid
        const quotesDoc = firebase
            .firestore()
            .collection('quotes')
            .doc(uid)
            .collection('snapshots')
            .doc('current')

        quotesDoc.onSnapshot(
            (snapshot) => {
                if (snapshot.exists && snapshot.data() && !_.isEmpty(snapshot.data())) {
                    console.log('fetched new quotes from firestore', snapshot.data())
                    setQuotes(snapshot.data() as ICurrentQuotes)
                    setLoading(false)
                }
            },
            (err) => {
                console.error('failed to retrieve quotes from firestore', err)
                setError(err)
                setLoading(false)
            },
        )
    }

    const refresh = () => {
        console.log('refreshing quotes...')
        setLoading(true)
        const refreshQuotes = firebase.functions().httpsCallable('refreshQuotes')
        return refreshQuotes().then(
            (result) => {
                const quotes = result.data as ICurrentQuotes
                setQuotes(quotes)
                setLoading(false)
            },
            (err) => {
                console.error('failed to retrieve quotes from API', err)
                setError(err)
                setLoading(false)
            },
        )
    }

    useEffect(() => {
        fetchQuotes()
    }, [])

    return {
        loading,
        quotes,
        error,
        refresh,
    }
}
