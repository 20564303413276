import firebase from 'firebase/app'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { IRebalancingAdjustment, IRebalancingAdjustments } from '../models'

export const useAdjustments = () => {
    const [adjustments, setAdjustments] = useState<IRebalancingAdjustment[]>([])
    const [error, setError] = useState<any>(null)
    const [loading, setLoading] = useState(true)

    const fetchAdjustments = () => {
        if (!firebase.auth().currentUser) {
            return
        }
        setLoading(true)
        const uid = firebase.auth().currentUser!.uid
        const docs = firebase
            .firestore()
            .collection('adjustments')
            .doc(uid)
            .collection('snapshots')

        docs.onSnapshot(
            (snapshot) => {
                if (snapshot.size && snapshot.docs.length) {
                    const adj = _.map(snapshot.docs, (doc) => {
                        return doc.data() as IRebalancingAdjustment
                    })
                    console.log('fetched adjustments from firestore', adj)
                    setAdjustments(adj)
                    setLoading(false)
                }
            },
            (err) => {
                console.error('failed to retrieve adjustments from firestore', err)
                setError(err)
                setLoading(false)
            },
        )
    }

    const save = async (name: string, adjustments: IRebalancingAdjustments) => {
        console.log('saving adjustments...')
        setLoading(true)
        const uid = firebase.auth().currentUser!.uid
        const adjustmentDoc = firebase
            .firestore()
            .collection('adjustments')
            .doc(uid)
            .collection('snapshots')
            .doc(name)

        const data = {
            name,
            adjustments,
            uid,
            updated: moment().toISOString(),
        }
        try {
            await adjustmentDoc.set(data)
            console.log('saved adjustment to firestore')
        } catch (err) {
            console.error('failed to save adjustment to firestore', err)
        }
    }

    const remove = async (name: string) => {
        console.log(`removing adjustment ${name}...`)
        setLoading(true)
        const uid = firebase.auth().currentUser!.uid
        const adjustmentDoc = firebase
            .firestore()
            .collection('adjustments')
            .doc(uid)
            .collection('snapshots')
            .doc(name)

        try {
            await adjustmentDoc.delete()
            console.log('removed adjustment from firestore')
        } catch (err) {
            console.error('failed to remove adjustment from firestore', err)
        }
    }

    useEffect(() => {
        fetchAdjustments()
    }, [])

    return {
        loading,
        adjustments,
        error,
        save,
        remove,
    }
}
