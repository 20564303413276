import numeral from 'numeral'
import React from 'react'

import { IConfig } from '../../models'

interface ICurrencyValueProps {
    config: IConfig
    value: number
    format?: string
}

const CurrencyValue: React.FC<ICurrencyValueProps> = (props) => {
    const { config, value, format } = props
    const formattedValue = value
        ? numeral(value).format(format || config.currencyFormat)
        : config.zeroValue
    return (
        <div
            className={`value currency ${
                !value ? 'zero' : value < 0 ? 'negative' : 'positive'
            }`}
        >
            {formattedValue}
        </div>
    )
}

export default CurrencyValue
