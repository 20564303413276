import {
    Button,
    FormGroup,
    HTMLSelect,
    HTMLTable,
    InputGroup,
    Intent,
    NumericInput,
    Switch,
    Toaster,
} from '@blueprintjs/core'
import firebase from 'firebase/app'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import Loading from '../components/Loading'
import { Actuals } from '../components/Rebalance/Actuals'
import { Diff } from '../components/Rebalance/Diff'
import { Percent } from '../components/Rebalance/Percent'
import { Spacer } from '../components/Rebalance/Spacer'
import { Targets } from '../components/Rebalance/Targets'
import { useAdjustments } from '../hooks/useAdjustments'
import {
    buildWorksheet,
    IRebalancingAccount,
    IRebalancingHolding,
    IRebalancingWorksheet,
    recalculate,
} from '../lib/rebalance'
import { IRebalancingAdjustments, IRebalancingHoldingAdjustment } from '../models'
import { colors } from '../theme'
import { MainContext } from './Main'

const NUM_COLS_HOLDING = 3
const NUM_COLS_CURRENTS = 4
const NUM_COLS_REBALANCED = 7

const CURRENTS_COLOR = '#4B4B5B'
const REBALANCED_COLOR = '#415876'
const TARGETS_COLOR = '#9A8D74'

const StyledRebalancingTable = styled(HTMLTable)`
    width: 100%;

    &&& thead {
        .spacer {
            td {
                height: 24px;
            }
        }
        td {
            font-weight: 700;
        }
    }
    &&& th {
        text-align: right;

        &.centered {
            text-align: center;
        }
        &.left {
            text-align: left;
        }
    }
    &&& td {
        vertical-align: middle;
        padding: 5px;
        font-size: 12px;
        text-align: right;

        &.centered {
            text-align: center;
        }
        &.left {
            text-align: left;
        }
        .bp3-control-group {
            justify-content: flex-end;
        }
        .bp3-control.bp3-inline {
            margin-right: 0 !important;
            margin: 0;
            padding: 0;
        }
        &.rebalanced {
            background-color: ${REBALANCED_COLOR}88;
            font-weight: 700;
        }
        &.Current {
            background-color: ${CURRENTS_COLOR}99;
        }
        &.Rebalanced {
            background-color: ${REBALANCED_COLOR}99;
        }
    }
    &&& tr {
        &.holding-totals,
        &.holding-rebalancing {
            td {
                padding: 8px 6px;
            }
        }
        &.account-totals {
            background-color: ${({ theme }) => theme.colors.typeHeading};
            font-weight: 700;
            td {
                font-size: 13px;
                padding: 12px 6px;
            }
        }
        &.grand-totals {
            background-color: ${({ theme }) => theme.colors.typeHeading};
            font-weight: 700;
            td {
                font-size: 13px;
                padding: 12px 6px;
            }
        }
        &.centered {
            td {
                text-align: center;
            }
        }
        &.targets {
            background-color: #9a8d744d; //${TARGETS_COLOR};
            &.overview {
                background-color: #9a8d747a; //${TARGETS_COLOR}bb;
            }
            td {
                white-space: nowrap;
            }
        }
        &.labels {
            td {
                color: #ffffff77;
            }
        }
        &.Current {
            background-color: #4b4b5b57; //${CURRENTS_COLOR};
            &.diff {
                background-color: transparent;
            }
            &.overview {
                background-color: #4b4b5b87; // ${CURRENTS_COLOR}bb;
            }
            td {
                white-space: nowrap;
            }
        }
        &.Rebalanced {
            background-color: #41587747; //${REBALANCED_COLOR};
            &.diff {
                background-color: transparent;
            }
            &.overview {
                background-color: #41587780; // ${REBALANCED_COLOR}bb;
            }
            td {
                white-space: nowrap;
            }
        }
        &.bold {
            td {
                font-weight: 700;
            }
        }
        &.overview {
            .title {
                font-size: 15px;
                vertical-align: middle;

                .value {
                    margin-top: 10px;
                    font-weight: 700;
                    font-size: 19px;
                }
            }
        }
    }

    .adjust-shares input {
        width: 75px;
        text-align: right;
        height: 25px;
        padding: 0;
    }
    .adjust-values input {
        width: 75px;
        text-align: right;
        height: 24px;
        padding: 0;
        line-height: 0;
    }
`

const toaster = Toaster.create({
    canEscapeKeyClear: true,
})

const Rebalancing: React.FC<{}> = () => {
    const context = useContext(MainContext)
    const {
        config,
        history: historyData,
        historyLoading,
        holdings: holdingsData,
        holdingsLoading,
        quotes: quotesData,
        quotesLoading,
    } = context

    const {
        adjustments: savedAdjustments,
        save: saveAdjustments,
        remove: removeAdjustments,
    } = useAdjustments()

    const [shouldRefresh, setShouldRefresh] = useState(false)
    const [defaultApplied, setDefaultApplied] = useState(false)
    const [adjustments, setAdjustments] = useState<IRebalancingAdjustments>({})
    const [worksheet, setWorksheet] = useState<IRebalancingWorksheet | null>(null)
    const [datasetName, setDatasetName] = useState('')

    const getAdjustmentKey = (
        account: IRebalancingAccount,
        holding: IRebalancingHolding,
    ): string => {
        return `${account.name}|${holding.symbol}`
    }

    const applyAdjustments = useCallback(
        (adjustments: IRebalancingAdjustments, worksheet: IRebalancingWorksheet) => {
            _.map(worksheet.accounts, (account) => {
                _.map(account.holdings, (holding) => {
                    const key = getAdjustmentKey(account, holding)
                    const adjustment = adjustments[key]
                    if (adjustment && adjustment.enabled) {
                        holding.rebalanced.shares =
                            holding.currents.shares + (adjustment.shares || 0)
                        if (adjustment.value || 0) {
                            const sharesForValue =
                                (adjustment.value || 0) / holding.currentValue
                            holding.rebalanced.shares += sharesForValue
                        }
                        holding.valueFactor = !_.isNil(adjustment.factor)
                            ? adjustment.factor / 100
                            : 1
                    } else {
                        holding.rebalanced.shares = holding.currents.shares
                        holding.valueFactor = 1
                    }
                })
            })
        },
        [],
    )

    const adjustHolding = (
        account: IRebalancingAccount,
        holding: IRebalancingHolding,
        adjustment: Partial<IRebalancingHoldingAdjustment>,
    ) => {
        if (!worksheet || !firebase.auth().currentUser) {
            return
        }
        const key = getAdjustmentKey(account, holding)
        const newAdjustments = {
            ...adjustments,
        }
        newAdjustments[key] = {
            ...newAdjustments[key],
            ...adjustment,
        }
        setAdjustments(newAdjustments)
    }

    const getAdjustments = (
        account: IRebalancingAccount,
        holding: IRebalancingHolding,
    ): IRebalancingHoldingAdjustment => {
        const key = getAdjustmentKey(account, holding)
        return (
            adjustments[key] || {
                enabled: false,
                shares: 0,
                value: 0,
                factor: 1,
            }
        )
    }

    // apply default
    useEffect(() => {
        if (!savedAdjustments.length) {
            return
        }
        // console.log('default', defaultApplied)
        if (!defaultApplied) {
            setDefaultApplied(true)
            const defaultSetName = 'Default'
            const defaultAdjustments = _.find(
                savedAdjustments,
                (adj) => adj.name === defaultSetName,
            )
            // console.log('default adjustments', defaultAdjustments)
            if (defaultAdjustments) {
                setDatasetName(defaultSetName)
                setAdjustments(defaultAdjustments.adjustments)
                toaster.show({
                    message: `Applied adjustments "${defaultSetName}".`,
                    timeout: 3000,
                    intent: Intent.PRIMARY,
                })
            }
        }
    }, [savedAdjustments, defaultApplied])

    useEffect(() => {
        if (shouldRefresh) {
            setShouldRefresh(false)
        }
    }, [shouldRefresh])

    useEffect(() => {
        // ignore if we don't have the data we need yet
        if (!historyData || !holdingsData || !quotesData) {
            return
        }
        const wk = buildWorksheet(historyData, holdingsData, quotesData)
        setWorksheet(wk)
        applyAdjustments(adjustments, wk)
        recalculate(wk)
        // console.log('bootstrapped worksheet', wk)
        setShouldRefresh(true)
    }, [historyData, holdingsData, quotesData, applyAdjustments, adjustments])

    // recalculate on adjustment changes
    useEffect(() => {
        if (!worksheet) {
            return
        }
        applyAdjustments(adjustments, worksheet)
        recalculate(worksheet)
        // console.log('calculated worksheet', worksheet, 'with', adjustments)
        setShouldRefresh(true)
    }, [adjustments, worksheet, applyAdjustments])

    if (!worksheet || !config || historyLoading || holdingsLoading || quotesLoading) {
        return <Loading message="Calculating..." />
    }

    const dropdownOptions = _.orderBy(
        savedAdjustments.map((a) => ({ label: a.name, value: a.name })),
        (opt) => opt.label.toLowerCase(),
    )
    dropdownOptions.splice(0, 0, { label: '(reset)', value: '(reset)' })
    dropdownOptions.splice(0, 0, { label: '(load)', value: '' })

    const currencyFormat = config.showCents ? '0,0.00' : '0,0'

    return (
        <>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    marginTop: 65,
                    verticalAlign: 'middle',
                    justifyContent: 'center',
                }}
            >
                {quotesData && (
                    <div
                        style={{
                            marginRight: 15,
                            marginTop: 7,
                            color: moment(quotesData.updated).isBefore(moment(), 'day')
                                ? '#EC6F76'
                                : '#ffffff99',
                        }}
                    >
                        Quotes as of {moment(quotesData.updated).format('LLL')}
                    </div>
                )}
                <div>
                    <HTMLSelect
                        value={''}
                        className="select"
                        options={dropdownOptions}
                        onChange={(event) => {
                            const selectedName = event.currentTarget.value
                            if (selectedName === '(reset)') {
                                setDatasetName('')
                                setAdjustments({})
                                toaster.show({
                                    message: 'Adjustments have been reset.',
                                    timeout: 3000,
                                    intent: Intent.PRIMARY,
                                })
                            } else if (selectedName) {
                                const adj = _.find(
                                    savedAdjustments,
                                    (a) => a.name === selectedName,
                                )
                                if (adj) {
                                    setDatasetName(selectedName)
                                    setAdjustments(adj.adjustments)
                                    toaster.show({
                                        message: `Applied adjustments "${selectedName}".`,
                                        timeout: 3000,
                                        intent: Intent.PRIMARY,
                                    })
                                }
                            }
                        }}
                    ></HTMLSelect>
                </div>
                <div style={{ marginLeft: 15 }}>
                    <FormGroup inline label="Dataset Name">
                        <InputGroup
                            value={datasetName}
                            style={{ width: 250 }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setDatasetName(event.target.value)
                            }}
                        />
                    </FormGroup>
                </div>
                <div style={{ marginLeft: 15 }}>
                    <Button
                        title="Save"
                        disabled={_.isEmpty(datasetName)}
                        onClick={() => {
                            return saveAdjustments(datasetName, adjustments).then(() => {
                                toaster.show({
                                    message: `Saved "${datasetName}".`,
                                    timeout: 3000,
                                    intent: Intent.SUCCESS,
                                })
                            })
                        }}
                    >
                        Save
                    </Button>
                </div>
                <div style={{ marginLeft: 15 }}>
                    <Button
                        title="Remove"
                        disabled={_.isEmpty(datasetName)}
                        onClick={() => {
                            return removeAdjustments(datasetName).then(() => {
                                setDatasetName('')
                                setAdjustments({})
                                toaster.show({
                                    message: `Removed "${datasetName}".`,
                                    timeout: 3000,
                                    intent: Intent.WARNING,
                                })
                            })
                        }}
                    >
                        Remove
                    </Button>
                </div>
            </div>
            <div style={{ flex: 1, display: 'flex', marginTop: 15 }}>
                <div style={{ flex: 1 }}>
                    <StyledRebalancingTable condensed>
                        <thead style={{ backgroundColor: colors.menuBar }}>
                            <tr>
                                <th colSpan={NUM_COLS_HOLDING}></th>
                                <th className="centered" colSpan={NUM_COLS_CURRENTS}>
                                    Current
                                </th>
                                <th className="centered" colSpan={NUM_COLS_REBALANCED}>
                                    Rebalanced
                                </th>
                            </tr>
                            <tr>
                                <th className="left">Name</th>
                                <th>Price</th>
                                <th>Factor</th>
                                <th>Shares</th>
                                <th>Value</th>
                                <th>%Tot</th>
                                <th>%Acct</th>
                                <th>Adj.</th>
                                <th>Shares</th>
                                <th>Value</th>
                                <th>%Tot</th>
                                <th>%Acct</th>
                                <th>ΔS</th>
                                <th>ΔV</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {_.map(_.orderBy(worksheet.accounts, a => a.currents.value, 'desc'), (account, accountIndex) => { */}
                            {_.map(worksheet.accounts, (account, accountIndex) => {
                                return (
                                    <React.Fragment key={`account${accountIndex}`}>
                                        {!!accountIndex && (
                                            <tr style={{ height: 25 }}>
                                                <td colSpan={999}></td>
                                            </tr>
                                        )}
                                        <tr className="account-totals">
                                            <td
                                                colSpan={NUM_COLS_HOLDING}
                                                className="left"
                                            >
                                                {account.name}
                                            </td>
                                            <td></td>
                                            <td className="Current">
                                                {numeral(account.currents.value).format(
                                                    currencyFormat,
                                                )}
                                            </td>
                                            <Percent
                                                value={account.currents.percentTotal}
                                            />
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="Rebalanced">
                                                {numeral(account.rebalanced.value).format(
                                                    currencyFormat,
                                                )}
                                            </td>
                                            <Percent
                                                value={account.rebalanced.percentTotal}
                                            />
                                            <td></td>
                                            <td></td>
                                            <Diff
                                                value={account.difference.value}
                                                format={currencyFormat}
                                            />
                                        </tr>
                                        {_.map(
                                            account.holdings,
                                            (holding, holdingIndex) => {
                                                return (
                                                    <React.Fragment
                                                        key={`holding${holdingIndex}`}
                                                    >
                                                        <tr className="holding-totals">
                                                            <td className="left">
                                                                {holding.symbol}
                                                            </td>
                                                            {/* <Percent value={holding.expenseRatio} format="%0.000" /> */}
                                                            <td>
                                                                {numeral(
                                                                    holding.currentValue,
                                                                ).format('0,0.00')}
                                                            </td>
                                                            {!getAdjustments(
                                                                account,
                                                                holding,
                                                            ).enabled && (
                                                                <Percent
                                                                    value={
                                                                        holding.valueFactor
                                                                    }
                                                                    format="%0"
                                                                />
                                                            )}
                                                            {getAdjustments(
                                                                account,
                                                                holding,
                                                            ).enabled && (
                                                                <td className="rebalanced">
                                                                    <NumericInput
                                                                        fill={false}
                                                                        buttonPosition="none"
                                                                        className="adjust-values"
                                                                        stepSize={1}
                                                                        majorStepSize={5}
                                                                        minorStepSize={
                                                                            0.5
                                                                        }
                                                                        min={0}
                                                                        max={100}
                                                                        rightElement={
                                                                            <div
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    paddingLeft: 5,
                                                                                    color: '#ffffff99',
                                                                                }}
                                                                            >
                                                                                %
                                                                            </div>
                                                                        }
                                                                        value={
                                                                            getAdjustments(
                                                                                account,
                                                                                holding,
                                                                            ).factor ||
                                                                            holding.valueFactor *
                                                                                100
                                                                        }
                                                                        onValueChange={(
                                                                            value,
                                                                        ) => {
                                                                            if (
                                                                                !_.isNaN(
                                                                                    value,
                                                                                )
                                                                            ) {
                                                                                adjustHolding(
                                                                                    account,
                                                                                    holding,
                                                                                    {
                                                                                        factor: value,
                                                                                    },
                                                                                )
                                                                            }
                                                                        }}
                                                                    />
                                                                </td>
                                                            )}

                                                            <td>
                                                                {numeral(
                                                                    holding.currents
                                                                        .shares,
                                                                ).format('0,0.000')}
                                                            </td>
                                                            <td className="Current">
                                                                {numeral(
                                                                    holding.currents
                                                                        .value,
                                                                ).format(currencyFormat)}
                                                            </td>
                                                            <Percent
                                                                value={
                                                                    holding.currents
                                                                        .percentTotal
                                                                }
                                                            />
                                                            <Percent
                                                                value={
                                                                    holding.currents
                                                                        .percentAccount
                                                                }
                                                            />

                                                            <td
                                                                className={
                                                                    getAdjustments(
                                                                        account,
                                                                        holding,
                                                                    ).enabled
                                                                        ? 'rebalanced'
                                                                        : ''
                                                                }
                                                            >
                                                                <Switch
                                                                    checked={
                                                                        getAdjustments(
                                                                            account,
                                                                            holding,
                                                                        ).enabled
                                                                    }
                                                                    inline={true}
                                                                    onChange={(event) => {
                                                                        adjustHolding(
                                                                            account,
                                                                            holding,
                                                                            {
                                                                                enabled:
                                                                                    event
                                                                                        .currentTarget
                                                                                        .checked,
                                                                            },
                                                                        )
                                                                    }}
                                                                />
                                                            </td>
                                                            <td
                                                                className={
                                                                    getAdjustments(
                                                                        account,
                                                                        holding,
                                                                    ).enabled
                                                                        ? 'rebalanced'
                                                                        : ''
                                                                }
                                                            >
                                                                {numeral(
                                                                    holding.rebalanced
                                                                        .shares,
                                                                ).format('0,0.000')}
                                                            </td>
                                                            <td
                                                                className={`Rebalanced ${
                                                                    getAdjustments(
                                                                        account,
                                                                        holding,
                                                                    ).enabled
                                                                        ? 'rebalanced'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {numeral(
                                                                    holding.rebalanced
                                                                        .value,
                                                                ).format(currencyFormat)}
                                                            </td>
                                                            <Percent
                                                                className={
                                                                    getAdjustments(
                                                                        account,
                                                                        holding,
                                                                    ).enabled
                                                                        ? 'rebalanced'
                                                                        : ''
                                                                }
                                                                value={
                                                                    holding.rebalanced
                                                                        .percentTotal
                                                                }
                                                            />
                                                            <Percent
                                                                className={
                                                                    getAdjustments(
                                                                        account,
                                                                        holding,
                                                                    ).enabled
                                                                        ? 'rebalanced'
                                                                        : ''
                                                                }
                                                                value={
                                                                    holding.rebalanced
                                                                        .percentAccount
                                                                }
                                                            />

                                                            <Diff
                                                                className={
                                                                    getAdjustments(
                                                                        account,
                                                                        holding,
                                                                    ).enabled
                                                                        ? 'rebalanced'
                                                                        : ''
                                                                }
                                                                value={
                                                                    holding.difference
                                                                        .shares
                                                                }
                                                                format="0,0.000"
                                                            />
                                                            <Diff
                                                                className={
                                                                    getAdjustments(
                                                                        account,
                                                                        holding,
                                                                    ).enabled
                                                                        ? 'rebalanced'
                                                                        : ''
                                                                }
                                                                value={
                                                                    holding.difference
                                                                        .value
                                                                }
                                                                format={currencyFormat}
                                                            />

                                                            {/* <Percent className="alloc centered" value={holding.equityStyle.largeCap.value * holding.assetAllocation.usEquity} />
                                            <Percent className="alloc centered" value={holding.equityStyle.largeCap.blend * holding.assetAllocation.usEquity} />
                                            <Percent className="alloc centered" value={holding.equityStyle.largeCap.growth * holding.assetAllocation.usEquity} />
                                            <Percent className="alloc centered" value={holding.equityStyle.midCap.value * holding.assetAllocation.usEquity} />
                                            <Percent className="alloc centered" value={holding.equityStyle.midCap.blend * holding.assetAllocation.usEquity} />
                                            <Percent className="alloc centered" value={holding.equityStyle.midCap.growth * holding.assetAllocation.usEquity} />
                                            <Percent className="alloc centered" value={holding.equityStyle.smallCap.value * holding.assetAllocation.usEquity} />
                                            <Percent className="alloc centered" value={holding.equityStyle.smallCap.blend * holding.assetAllocation.usEquity} />
                                            <Percent className="alloc centered" value={holding.equityStyle.smallCap.growth * holding.assetAllocation.usEquity} />
                                            <Percent className="alloc centered" value={holding.assetAllocation.internationalEquity} />
                                            <Percent className="alloc centered" value={holding.assetAllocation.fixedIncome} />
                                            <Percent className="alloc centered" value={holding.assetAllocation.cash + holding.assetAllocation.other} /> */}
                                                        </tr>
                                                        {getAdjustments(account, holding)
                                                            .enabled && (
                                                            <tr className="holding-rebalancing">
                                                                <td
                                                                    colSpan={
                                                                        NUM_COLS_HOLDING +
                                                                        NUM_COLS_CURRENTS
                                                                    }
                                                                ></td>
                                                                <td className="rebalanced"></td>
                                                                <td className="rebalanced">
                                                                    <NumericInput
                                                                        fill={false}
                                                                        buttonPosition="none"
                                                                        className="adjust-shares"
                                                                        value={
                                                                            getAdjustments(
                                                                                account,
                                                                                holding,
                                                                            ).shares
                                                                        }
                                                                        onValueChange={(
                                                                            value,
                                                                        ) => {
                                                                            if (
                                                                                !_.isNaN(
                                                                                    value,
                                                                                )
                                                                            ) {
                                                                                adjustHolding(
                                                                                    account,
                                                                                    holding,
                                                                                    {
                                                                                        shares: value,
                                                                                    },
                                                                                )
                                                                            }
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td className="rebalanced">
                                                                    <NumericInput
                                                                        fill={false}
                                                                        buttonPosition="none"
                                                                        className="adjust-values"
                                                                        stepSize={100}
                                                                        majorStepSize={
                                                                            500
                                                                        }
                                                                        value={
                                                                            getAdjustments(
                                                                                account,
                                                                                holding,
                                                                            ).value
                                                                        }
                                                                        onValueChange={(
                                                                            value,
                                                                        ) => {
                                                                            if (
                                                                                !_.isNaN(
                                                                                    value,
                                                                                )
                                                                            ) {
                                                                                adjustHolding(
                                                                                    account,
                                                                                    holding,
                                                                                    {
                                                                                        value: value,
                                                                                    },
                                                                                )
                                                                            }
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td className="rebalanced">
                                                                    {numeral(
                                                                        getAdjustments(
                                                                            account,
                                                                            holding,
                                                                        ).value /
                                                                            holding.currentValue,
                                                                    ).format('0,0.000')}
                                                                </td>
                                                                <td className="rebalanced"></td>
                                                                <td className=""></td>
                                                                <td className=""></td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                )
                                            },
                                        )}
                                    </React.Fragment>
                                )
                            })}

                            <Spacer />

                            <tr className="grand-totals">
                                <td className="left" colSpan={NUM_COLS_HOLDING}>
                                    Grand Totals
                                </td>

                                <td></td>
                                <td className="Current">
                                    {numeral(worksheet.currents.value).format('0,0.00')}
                                </td>
                                <td></td>
                                <td></td>

                                <td></td>
                                <td></td>
                                <td className="Rebalanced">
                                    {numeral(worksheet.rebalanced.value).format('0,0.00')}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <Diff
                                    value={worksheet.difference.value}
                                    format="0,0.00"
                                />
                            </tr>
                        </tbody>
                    </StyledRebalancingTable>
                </div>

                <div style={{ marginLeft: 30, flex: 0 }}>
                    <StyledRebalancingTable condensed>
                        <tbody>
                            <Targets worksheet={worksheet} />
                            <Spacer />
                            <Actuals
                                title="Current"
                                worksheet={worksheet}
                                values={worksheet.currents}
                            />
                            <Spacer />
                            <Actuals
                                title="Rebalanced"
                                worksheet={worksheet}
                                values={worksheet.rebalanced}
                            />
                        </tbody>
                    </StyledRebalancingTable>
                </div>
            </div>
        </>
    )
}

export default Rebalancing
