import _ from 'lodash'
import moment from 'moment'

import {
    IAccountsHistory,
    IAssetAllocation,
    ICurrentHoldings,
    ICurrentQuotes,
    IEquityStyle,
    IHolding,
} from '../models'

const initEquityStyle = (): IEquityStyle => {
    return {
        largeCap: {
            value: 0,
            blend: 0,
            growth: 0,
        },
        midCap: {
            value: 0,
            blend: 0,
            growth: 0,
        },
        smallCap: {
            value: 0,
            blend: 0,
            growth: 0,
        },
    }
}

const initAssetAllocation = (): IAssetAllocation => {
    return {
        usEquity: 0,
        internationalEquity: 0,
        fixedIncome: 0,
        cash: 0,
        other: 0,
    }
}

export interface IRebalancingHoldingValues {
    shares: number
    value: number
    percentTotal: number
    percentAccount: number

    valuesByEquityStyle: IEquityStyle
    valuesByAssetAllocation: IAssetAllocation
}

export interface IRebalancingHolding extends IHolding {
    currentValue: number
    asOf: moment.Moment
    valueFactor: number

    currents: IRebalancingHoldingValues
    rebalanced: IRebalancingHoldingValues
    difference: IRebalancingHoldingValues
}

export interface IRebalancingAccountValues {
    value: number
    percentTotal: number

    valuesByEquityStyle: IEquityStyle
    valuesByAssetAllocation: IAssetAllocation
}

export interface IRebalancingAccount {
    name: string
    institution: string

    holdings: IRebalancingHolding[]

    currents: IRebalancingAccountValues
    rebalanced: IRebalancingAccountValues
    difference: IRebalancingAccountValues
}

export interface IRebalancingWorksheetValues {
    value: number

    valuesByEquityStyle: IEquityStyle
    valuesByAssetAllocation: IAssetAllocation

    percentsByEquityStyle: IEquityStyle
    percentsByAssetAllocation: IAssetAllocation
}

export interface IRebalancingWorksheet {
    accounts: IRebalancingAccount[]

    targetPercentByEquityStyle: IEquityStyle
    targetPercentByAssetAllocation: IAssetAllocation

    currents: IRebalancingWorksheetValues
    rebalanced: IRebalancingWorksheetValues
    difference: IRebalancingWorksheetValues
}

export const buildWorksheet = (
    history: IAccountsHistory,
    holdings: ICurrentHoldings,
    quotes: ICurrentQuotes,
): IRebalancingWorksheet => {
    const targetUs = 0.6
    const targetIntl = 0.24
    const targetBonds = 0.155
    const targetCash = 0.005

    const revisedHistory = _.cloneDeep(history)

    // combine 401k holdings
    const account401k = _.find(
        revisedHistory.accounts,
        (a) => a.account.name === '401(k)',
    )
    const account401kBrokerage = _.find(
        revisedHistory.accounts,
        (a) => a.account.name === '401(k) BrokerageLink',
    )
    if (account401k && account401kBrokerage) {
        account401k.account.holdings = account401k.account.holdings!.concat(
            account401kBrokerage.account.holdings!,
        )
        revisedHistory.accounts.splice(
            _.indexOf(revisedHistory.accounts, account401kBrokerage),
            1,
        )
    }

    const accountsWithHoldings = _.chain(revisedHistory.accounts)
        .filter((accountHistory) => {
            return (
                !!accountHistory.account.holdings &&
                !!accountHistory.account.holdings.length
            )
        })
        .map((accountHistory) => {
            return accountHistory.account
        })
        .value()

    return {
        accounts: _.map(accountsWithHoldings, (account) => {
            return {
                name: account.name,
                institution: account.institution,
                holdings: _.compact(
                    _.map(account.holdings, (holding) => {
                        const holdingDetail = _.find(
                            holdings.holdings,
                            (h) => h.symbol === holding.symbol,
                        )
                        if (!holdingDetail) {
                            console.error('no holding found for symbol', holding.symbol)
                            return null
                        }
                        const currentQuote = _.find(
                            quotes.quotes,
                            (q) => q.symbol === holding.symbol,
                        )
                        if (!currentQuote) {
                            console.error('no quote found for symbol', holding.symbol)
                            return null
                        }
                        return {
                            ...holdingDetail,
                            currentValue: currentQuote.price,
                            valueFactor: 1, //holding.symbol !== 'AAPL' ? 1 : 0,
                            asOf: moment(currentQuote.asOf),
                            currents: {
                                shares: holding.shares,
                                value: 0,
                                percentTotal: 0,
                                percentAccount: 0,
                                valuesByEquityStyle: initEquityStyle(),
                                valuesByAssetAllocation: initAssetAllocation(),
                            },
                            rebalanced: {
                                shares: holding.shares,
                                value: 0,
                                percentTotal: 0,
                                percentAccount: 0,
                                valuesByEquityStyle: initEquityStyle(),
                                valuesByAssetAllocation: initAssetAllocation(),
                            },
                            difference: {
                                shares: 0,
                                value: 0,
                                percentTotal: 0,
                                percentAccount: 0,
                                valuesByEquityStyle: initEquityStyle(),
                                valuesByAssetAllocation: initAssetAllocation(),
                            },
                        } as IRebalancingHolding
                    }),
                ),
                currents: {
                    value: 0,
                    percentTotal: 0,
                    valuesByEquityStyle: initEquityStyle(),
                    valuesByAssetAllocation: initAssetAllocation(),
                },
                rebalanced: {
                    value: 0,
                    percentTotal: 0,
                    valuesByEquityStyle: initEquityStyle(),
                    valuesByAssetAllocation: initAssetAllocation(),
                },
                difference: {
                    value: 0,
                    percentTotal: 0,
                    valuesByEquityStyle: initEquityStyle(),
                    valuesByAssetAllocation: initAssetAllocation(),
                },
            } as IRebalancingAccount
        }),
        targetPercentByEquityStyle: {
            largeCap: {
                growth: (targetUs * 0.76 * 1) / 3,
                blend: (targetUs * 0.76 * 1) / 3,
                value: (targetUs * 0.76 * 1) / 3,
            },
            midCap: {
                growth: (targetUs * 0.18 * 1) / 3,
                blend: (targetUs * 0.18 * 1) / 3,
                value: (targetUs * 0.18 * 1) / 3,
            },
            smallCap: {
                growth: (targetUs * 0.06 * 1) / 3,
                blend: (targetUs * 0.06 * 1) / 3,
                value: (targetUs * 0.06 * 1) / 3,
            },
        },
        targetPercentByAssetAllocation: {
            usEquity: targetUs,
            internationalEquity: targetIntl,
            fixedIncome: targetBonds,
            cash: targetCash,
            other: 0,
        },
        currents: {
            value: 0,
            valuesByEquityStyle: initEquityStyle(),
            valuesByAssetAllocation: initAssetAllocation(),
            percentsByEquityStyle: initEquityStyle(),
            percentsByAssetAllocation: initAssetAllocation(),
        },
        rebalanced: {
            value: 0,
            valuesByEquityStyle: initEquityStyle(),
            valuesByAssetAllocation: initAssetAllocation(),
            percentsByEquityStyle: initEquityStyle(),
            percentsByAssetAllocation: initAssetAllocation(),
        },
        difference: {
            value: 0,
            valuesByEquityStyle: initEquityStyle(),
            valuesByAssetAllocation: initAssetAllocation(),
            percentsByEquityStyle: initEquityStyle(),
            percentsByAssetAllocation: initAssetAllocation(),
        },
    }
}

const calculateRebalancingHoldingValues = (
    holding: IRebalancingHolding,
    holdingValue: number,
    holdingValues: IRebalancingHoldingValues,
): IRebalancingHoldingValues => {
    const usEquityValue = holding.assetAllocation.usEquity * holdingValue
    return {
        ...holdingValues,
        value: holdingValue,
        valuesByAssetAllocation: {
            usEquity: usEquityValue,
            internationalEquity:
                holding.assetAllocation.internationalEquity * holdingValue,
            fixedIncome: holding.assetAllocation.fixedIncome * holdingValue,
            cash: holding.assetAllocation.cash * holdingValue,
            other: holding.assetAllocation.other * holdingValue,
        },
        // note: only domestic equities are counted here
        valuesByEquityStyle: {
            largeCap: {
                growth: holding.equityStyle.largeCap.growth * usEquityValue,
                blend: holding.equityStyle.largeCap.blend * usEquityValue,
                value: holding.equityStyle.largeCap.value * usEquityValue,
            },
            midCap: {
                growth: holding.equityStyle.midCap.growth * usEquityValue,
                blend: holding.equityStyle.midCap.blend * usEquityValue,
                value: holding.equityStyle.midCap.value * usEquityValue,
            },
            smallCap: {
                growth: holding.equityStyle.smallCap.growth * usEquityValue,
                blend: holding.equityStyle.smallCap.blend * usEquityValue,
                value: holding.equityStyle.smallCap.value * usEquityValue,
            },
        },
    }
}

const calculateRebalancingHoldingDifferences = (
    currents: IRebalancingHoldingValues,
    rebalanced: IRebalancingHoldingValues,
): IRebalancingHoldingValues => {
    const from = rebalanced
    const to = currents

    return {
        // can't calculate these yet
        percentTotal: 0,
        percentAccount: 0,

        shares: from.shares - to.shares,
        value: from.value - to.value,
        valuesByAssetAllocation: {
            usEquity:
                from.valuesByAssetAllocation.usEquity -
                to.valuesByAssetAllocation.usEquity,
            internationalEquity:
                from.valuesByAssetAllocation.internationalEquity -
                to.valuesByAssetAllocation.internationalEquity,
            fixedIncome:
                from.valuesByAssetAllocation.fixedIncome -
                to.valuesByAssetAllocation.fixedIncome,
            cash: from.valuesByAssetAllocation.cash - to.valuesByAssetAllocation.cash,
            other: from.valuesByAssetAllocation.other - to.valuesByAssetAllocation.other,
        },
        valuesByEquityStyle: {
            largeCap: {
                growth:
                    from.valuesByEquityStyle.largeCap.growth -
                    to.valuesByEquityStyle.largeCap.growth,
                blend:
                    from.valuesByEquityStyle.largeCap.blend -
                    to.valuesByEquityStyle.largeCap.blend,
                value:
                    from.valuesByEquityStyle.largeCap.value -
                    to.valuesByEquityStyle.largeCap.value,
            },
            midCap: {
                growth:
                    from.valuesByEquityStyle.midCap.growth -
                    to.valuesByEquityStyle.midCap.growth,
                blend:
                    from.valuesByEquityStyle.midCap.blend -
                    to.valuesByEquityStyle.midCap.blend,
                value:
                    from.valuesByEquityStyle.midCap.value -
                    to.valuesByEquityStyle.midCap.value,
            },
            smallCap: {
                growth:
                    from.valuesByEquityStyle.smallCap.growth -
                    to.valuesByEquityStyle.smallCap.growth,
                blend:
                    from.valuesByEquityStyle.smallCap.blend -
                    to.valuesByEquityStyle.smallCap.blend,
                value:
                    from.valuesByEquityStyle.smallCap.value -
                    to.valuesByEquityStyle.smallCap.value,
            },
        },
    }
}

const calculateHolding = (holding: IRebalancingHolding): IRebalancingHolding => {
    // calculate current
    const holdingCurrentValue =
        (holding.currents.shares || 0) * holding.currentValue * holding.valueFactor
    // calculate rebalanced
    const holdingRebalancingValue =
        (holding.rebalanced.shares || 0) * holding.currentValue * holding.valueFactor
    const newHolding = {
        ...holding,
        currents: calculateRebalancingHoldingValues(
            holding,
            holdingCurrentValue,
            holding.currents,
        ),
        rebalanced: calculateRebalancingHoldingValues(
            holding,
            holdingRebalancingValue,
            holding.rebalanced,
        ),
    }
    // calculate difference
    newHolding.difference = calculateRebalancingHoldingDifferences(
        newHolding.currents,
        newHolding.rebalanced,
    )
    return newHolding
}

const calculateRebalancingAccountValues = (
    holdings: IRebalancingHolding[],
    type: 'currents' | 'rebalanced',
): IRebalancingAccountValues => {
    return {
        // can't calculate this yet
        percentTotal: 0,

        value: _.sumBy(holdings, (holding) => holding[type].value),
        valuesByEquityStyle: {
            largeCap: {
                growth: _.sumBy(
                    holdings,
                    (holding) => holding[type].valuesByEquityStyle.largeCap.growth,
                ),
                blend: _.sumBy(
                    holdings,
                    (holding) => holding[type].valuesByEquityStyle.largeCap.blend,
                ),
                value: _.sumBy(
                    holdings,
                    (holding) => holding[type].valuesByEquityStyle.largeCap.value,
                ),
            },
            midCap: {
                growth: _.sumBy(
                    holdings,
                    (holding) => holding[type].valuesByEquityStyle.midCap.growth,
                ),
                blend: _.sumBy(
                    holdings,
                    (holding) => holding[type].valuesByEquityStyle.midCap.blend,
                ),
                value: _.sumBy(
                    holdings,
                    (holding) => holding[type].valuesByEquityStyle.midCap.value,
                ),
            },
            smallCap: {
                growth: _.sumBy(
                    holdings,
                    (holding) => holding[type].valuesByEquityStyle.smallCap.growth,
                ),
                blend: _.sumBy(
                    holdings,
                    (holding) => holding[type].valuesByEquityStyle.smallCap.blend,
                ),
                value: _.sumBy(
                    holdings,
                    (holding) => holding[type].valuesByEquityStyle.smallCap.value,
                ),
            },
        },
        valuesByAssetAllocation: {
            usEquity: _.sumBy(
                holdings,
                (holding) => holding[type].valuesByAssetAllocation.usEquity,
            ),
            internationalEquity: _.sumBy(
                holdings,
                (holding) => holding[type].valuesByAssetAllocation.internationalEquity,
            ),
            fixedIncome: _.sumBy(
                holdings,
                (holding) => holding[type].valuesByAssetAllocation.fixedIncome,
            ),
            cash: _.sumBy(
                holdings,
                (holding) => holding[type].valuesByAssetAllocation.cash,
            ),
            other: _.sumBy(
                holdings,
                (holding) => holding[type].valuesByAssetAllocation.other,
            ),
        },
    }
}

const calculateRebalancingAccountDifferences = (
    currents: IRebalancingAccountValues,
    rebalanced: IRebalancingAccountValues,
): IRebalancingAccountValues => {
    const from = rebalanced
    const to = currents

    return {
        // can't calculate this yet
        percentTotal: 0,

        value: from.value - to.value,
        valuesByAssetAllocation: {
            usEquity:
                from.valuesByAssetAllocation.usEquity -
                to.valuesByAssetAllocation.usEquity,
            internationalEquity:
                from.valuesByAssetAllocation.internationalEquity -
                to.valuesByAssetAllocation.internationalEquity,
            fixedIncome:
                from.valuesByAssetAllocation.fixedIncome -
                to.valuesByAssetAllocation.fixedIncome,
            cash: from.valuesByAssetAllocation.cash - to.valuesByAssetAllocation.cash,
            other: from.valuesByAssetAllocation.other - to.valuesByAssetAllocation.other,
        },
        valuesByEquityStyle: {
            largeCap: {
                growth:
                    from.valuesByEquityStyle.largeCap.growth -
                    to.valuesByEquityStyle.largeCap.growth,
                blend:
                    from.valuesByEquityStyle.largeCap.blend -
                    to.valuesByEquityStyle.largeCap.blend,
                value:
                    from.valuesByEquityStyle.largeCap.value -
                    to.valuesByEquityStyle.largeCap.value,
            },
            midCap: {
                growth:
                    from.valuesByEquityStyle.midCap.growth -
                    to.valuesByEquityStyle.midCap.growth,
                blend:
                    from.valuesByEquityStyle.midCap.blend -
                    to.valuesByEquityStyle.midCap.blend,
                value:
                    from.valuesByEquityStyle.midCap.value -
                    to.valuesByEquityStyle.midCap.value,
            },
            smallCap: {
                growth:
                    from.valuesByEquityStyle.smallCap.growth -
                    to.valuesByEquityStyle.smallCap.growth,
                blend:
                    from.valuesByEquityStyle.smallCap.blend -
                    to.valuesByEquityStyle.smallCap.blend,
                value:
                    from.valuesByEquityStyle.smallCap.value -
                    to.valuesByEquityStyle.smallCap.value,
            },
        },
    }
}

const calculateAccount = (account: IRebalancingAccount): IRebalancingAccount => {
    // calculate account totals
    const calculatedAccount: IRebalancingAccount = {
        ...account,
        currents: calculateRebalancingAccountValues(account.holdings, 'currents'),
        rebalanced: calculateRebalancingAccountValues(account.holdings, 'rebalanced'),
    }
    // calculate difference
    calculatedAccount.difference = calculateRebalancingAccountDifferences(
        calculatedAccount.currents,
        calculatedAccount.rebalanced,
    )

    // calculate holding percents of account
    _.each(calculatedAccount.holdings, (holding) => {
        holding.currents.percentAccount = calculatedAccount.currents.value
            ? holding.currents.value / calculatedAccount.currents.value
            : 0
        holding.rebalanced.percentAccount = calculatedAccount.rebalanced.value
            ? holding.rebalanced.value / calculatedAccount.rebalanced.value
            : 0
        holding.difference.percentAccount =
            holding.currents.percentAccount - holding.rebalanced.percentAccount
    })

    return calculatedAccount
}

const calculateRebalancingWorksheetValues = (
    accounts: IRebalancingAccount[],
    type: 'currents' | 'rebalanced',
): IRebalancingWorksheetValues => {
    const totalValue = _.sumBy(accounts, (a) => a[type].value)
    const values = {
        value: totalValue,
        valuesByEquityStyle: {
            largeCap: {
                growth: _.sumBy(
                    accounts,
                    (a) => a[type].valuesByEquityStyle.largeCap.growth,
                ),
                blend: _.sumBy(
                    accounts,
                    (a) => a[type].valuesByEquityStyle.largeCap.blend,
                ),
                value: _.sumBy(
                    accounts,
                    (a) => a[type].valuesByEquityStyle.largeCap.value,
                ),
            },
            midCap: {
                growth: _.sumBy(
                    accounts,
                    (a) => a[type].valuesByEquityStyle.midCap.growth,
                ),
                blend: _.sumBy(accounts, (a) => a[type].valuesByEquityStyle.midCap.blend),
                value: _.sumBy(accounts, (a) => a[type].valuesByEquityStyle.midCap.value),
            },
            smallCap: {
                growth: _.sumBy(
                    accounts,
                    (a) => a[type].valuesByEquityStyle.smallCap.growth,
                ),
                blend: _.sumBy(
                    accounts,
                    (a) => a[type].valuesByEquityStyle.smallCap.blend,
                ),
                value: _.sumBy(
                    accounts,
                    (a) => a[type].valuesByEquityStyle.smallCap.value,
                ),
            },
        },
        valuesByAssetAllocation: {
            usEquity: _.sumBy(accounts, (a) => a[type].valuesByAssetAllocation.usEquity),
            internationalEquity: _.sumBy(
                accounts,
                (a) => a[type].valuesByAssetAllocation.internationalEquity,
            ),
            fixedIncome: _.sumBy(
                accounts,
                (a) => a[type].valuesByAssetAllocation.fixedIncome,
            ),
            cash: _.sumBy(accounts, (a) => a[type].valuesByAssetAllocation.cash),
            other: _.sumBy(accounts, (a) => a[type].valuesByAssetAllocation.other),
        },
    }

    return {
        ...values,
        percentsByEquityStyle: {
            largeCap: {
                growth: values.valuesByEquityStyle.largeCap.growth / totalValue,
                blend: values.valuesByEquityStyle.largeCap.blend / totalValue,
                value: values.valuesByEquityStyle.largeCap.value / totalValue,
            },
            midCap: {
                growth: values.valuesByEquityStyle.midCap.growth / totalValue,
                blend: values.valuesByEquityStyle.midCap.blend / totalValue,
                value: values.valuesByEquityStyle.midCap.value / totalValue,
            },
            smallCap: {
                growth: values.valuesByEquityStyle.smallCap.growth / totalValue,
                blend: values.valuesByEquityStyle.smallCap.blend / totalValue,
                value: values.valuesByEquityStyle.smallCap.value / totalValue,
            },
        },
        percentsByAssetAllocation: {
            usEquity: values.valuesByAssetAllocation.usEquity / totalValue,
            internationalEquity:
                values.valuesByAssetAllocation.internationalEquity / totalValue,
            fixedIncome: values.valuesByAssetAllocation.fixedIncome / totalValue,
            cash: values.valuesByAssetAllocation.cash / totalValue,
            other: values.valuesByAssetAllocation.other / totalValue,
        },
    }
}

const calculateRebalancingWorksheetDifferences = (
    currents: IRebalancingWorksheetValues,
    rebalanced: IRebalancingWorksheetValues,
): IRebalancingWorksheetValues => {
    const from = rebalanced
    const to = currents

    return {
        value: from.value - to.value,
        valuesByAssetAllocation: {
            usEquity:
                from.valuesByAssetAllocation.usEquity -
                to.valuesByAssetAllocation.usEquity,
            internationalEquity:
                from.valuesByAssetAllocation.internationalEquity -
                to.valuesByAssetAllocation.internationalEquity,
            fixedIncome:
                from.valuesByAssetAllocation.fixedIncome -
                to.valuesByAssetAllocation.fixedIncome,
            cash: from.valuesByAssetAllocation.cash - to.valuesByAssetAllocation.cash,
            other: from.valuesByAssetAllocation.other - to.valuesByAssetAllocation.other,
        },
        valuesByEquityStyle: {
            largeCap: {
                growth:
                    from.valuesByEquityStyle.largeCap.growth -
                    to.valuesByEquityStyle.largeCap.growth,
                blend:
                    from.valuesByEquityStyle.largeCap.blend -
                    to.valuesByEquityStyle.largeCap.blend,
                value:
                    from.valuesByEquityStyle.largeCap.value -
                    to.valuesByEquityStyle.largeCap.value,
            },
            midCap: {
                growth:
                    from.valuesByEquityStyle.midCap.growth -
                    to.valuesByEquityStyle.midCap.growth,
                blend:
                    from.valuesByEquityStyle.midCap.blend -
                    to.valuesByEquityStyle.midCap.blend,
                value:
                    from.valuesByEquityStyle.midCap.value -
                    to.valuesByEquityStyle.midCap.value,
            },
            smallCap: {
                growth:
                    from.valuesByEquityStyle.smallCap.growth -
                    to.valuesByEquityStyle.smallCap.growth,
                blend:
                    from.valuesByEquityStyle.smallCap.blend -
                    to.valuesByEquityStyle.smallCap.blend,
                value:
                    from.valuesByEquityStyle.smallCap.value -
                    to.valuesByEquityStyle.smallCap.value,
            },
        },
        percentsByEquityStyle: {
            largeCap: {
                growth:
                    from.percentsByEquityStyle.largeCap.growth -
                    to.percentsByEquityStyle.largeCap.growth,
                blend:
                    from.percentsByEquityStyle.largeCap.blend -
                    to.percentsByEquityStyle.largeCap.blend,
                value:
                    from.percentsByEquityStyle.largeCap.value -
                    to.percentsByEquityStyle.largeCap.value,
            },
            midCap: {
                growth:
                    from.percentsByEquityStyle.midCap.growth -
                    to.percentsByEquityStyle.midCap.growth,
                blend:
                    from.percentsByEquityStyle.midCap.blend -
                    to.percentsByEquityStyle.midCap.blend,
                value:
                    from.percentsByEquityStyle.midCap.value -
                    to.percentsByEquityStyle.midCap.value,
            },
            smallCap: {
                growth:
                    from.percentsByEquityStyle.smallCap.growth -
                    to.percentsByEquityStyle.smallCap.growth,
                blend:
                    from.percentsByEquityStyle.smallCap.blend -
                    to.percentsByEquityStyle.smallCap.blend,
                value:
                    from.percentsByEquityStyle.smallCap.value -
                    to.percentsByEquityStyle.smallCap.value,
            },
        },
        percentsByAssetAllocation: {
            usEquity:
                from.percentsByAssetAllocation.usEquity -
                to.percentsByAssetAllocation.usEquity,
            internationalEquity:
                from.percentsByAssetAllocation.internationalEquity -
                to.percentsByAssetAllocation.internationalEquity,
            fixedIncome:
                from.percentsByAssetAllocation.fixedIncome -
                to.percentsByAssetAllocation.fixedIncome,
            cash: from.percentsByAssetAllocation.cash - to.percentsByAssetAllocation.cash,
            other:
                from.percentsByAssetAllocation.other - to.percentsByAssetAllocation.other,
        },
    }
}

const calculateWorksheet = (worksheet: IRebalancingWorksheet) => {
    // calculate worksheet totals
    worksheet.currents = calculateRebalancingWorksheetValues(
        worksheet.accounts,
        'currents',
    )
    worksheet.rebalanced = calculateRebalancingWorksheetValues(
        worksheet.accounts,
        'rebalanced',
    )
    // const calculatedWorksheet: IRebalancingWorksheet = {
    //     ...worksheet,
    //     currents: calculateRebalancingWorksheetValues(worksheet.accounts, 'currents'),
    //     rebalanced: calculateRebalancingWorksheetValues(worksheet.accounts, 'rebalanced')
    // }
    // calculate difference
    worksheet.difference = calculateRebalancingWorksheetDifferences(
        worksheet.currents,
        worksheet.rebalanced,
    )

    // calculate accounts percent of total
    _.each(worksheet.accounts, (account) => {
        account.currents.percentTotal = worksheet.currents.value
            ? account.currents.value / worksheet.currents.value
            : 0
        account.rebalanced.percentTotal = worksheet.rebalanced.value
            ? account.rebalanced.value / worksheet.rebalanced.value
            : 0
        account.difference.percentTotal =
            account.currents.percentTotal - account.difference.percentTotal

        // calculate holding percents of total
        _.each(account.holdings, (holding) => {
            holding.currents.percentTotal = worksheet.currents.value
                ? holding.currents.value / worksheet.currents.value
                : 0
            holding.rebalanced.percentTotal = worksheet.rebalanced.value
                ? holding.rebalanced.value / worksheet.rebalanced.value
                : 0
            holding.difference.percentTotal =
                holding.currents.percentTotal - holding.difference.percentTotal
        })
    })
}

export const recalculate = (worksheet: IRebalancingWorksheet) => {
    console.time('recalculate')

    // console.time('withHoldingsCalculated')
    // calculate individual holdings
    _.map(worksheet.accounts, (account) => {
        account.holdings = _.map(account.holdings, (holding) => calculateHolding(holding))
    })
    // const withHoldingsCalculated: IRebalancingWorksheet = {
    //     ...worksheet,
    //     accounts: _.map(worksheet.accounts, account => {
    //         return {
    //             ...account,
    //             holdings: _.map(account.holdings, holding => calculateHolding(holding))
    //         }
    //     })
    // }
    // console.timeEnd('withHoldingsCalculated')
    // console.log('recalculate:withHoldingsCalculated', withHoldingsCalculated)

    // console.time('withAccountsCalculated')
    // calculate account totals
    worksheet.accounts = _.map(worksheet.accounts, (account) => calculateAccount(account))
    // const withAccountsCalculated: IRebalancingWorksheet = {
    //     ...withHoldingsCalculated,
    //     accounts: _.map(withHoldingsCalculated.accounts, account => calculateAccount(account))
    // }
    // console.timeEnd('withAccountsCalculated')
    // console.log('recalculate:withAccountsCalculated', withAccountsCalculated)

    // console.time('withWorksheetCalculated')
    // calculate worksheet totals
    calculateWorksheet(worksheet)
    // console.timeEnd('withWorksheetCalculated')
    // console.log('recalculate:withWorksheetCalculated', withWorksheetCalculated)

    console.timeEnd('recalculate')

    // return withWorksheetCalculated
}
