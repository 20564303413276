import { Icon } from '@blueprintjs/core'
import _ from 'lodash'
import numeral from 'numeral'
import React, { TdHTMLAttributes } from 'react'
import styled from 'styled-components'

export interface IDiffComponentProps extends TdHTMLAttributes<HTMLTableDataCellElement> {
    value: number | null
    format?: string
}

export const DiffComponent: React.FC<IDiffComponentProps> = (props) => {
    const { value, className, format, ...rest } = props
    const roundedValue = _.round(value || 0, 4)

    if (!value || !roundedValue) {
        return (
            <td {...rest} className={`${className} zero`}>
                {_.isNil(value) ? '' : '-'}
            </td>
        )
    }
    const modClass =
        Math.abs(value) >= 0.006 ? 'major' : Math.abs(value) >= 0.002 ? 'minor' : ''
    const formattedValue = `${numeral(value || 0)
        .format(format || '0.00%')
        .replace(/-/g, '')}`
    return (
        <td
            {...rest}
            className={`${className} ${modClass} ${
                !value ? 'zero' : value < 0 ? 'negative' : 'positive'
            }`}
        >
            {value > 0 && <Icon className="icon" iconSize={16} icon="caret-up" />}
            {value < 0 && <Icon className="icon" iconSize={16} icon="caret-down" />}
            {formattedValue}
        </td>
    )
}

export const Diff = styled(DiffComponent)`
    &&& {
        &.zero {
            color: ${({ theme }) => theme.colors.valueZero};
            opacity: 0.7;
        }
        &.positive {
            color: ${({ theme }) => theme.colors.valueGain}88;
        }
        &.negative {
            color: ${({ theme }) => theme.colors.valueLoss}88;
        }
        &.minor {
            color: white;
            &.positive {
                // background-color: ${({ theme }) => theme.colors.valueGain}33;
                color: ${({ theme }) => theme.colors.valueGain}cc;
            }
            &.negative {
                // background-color: ${({ theme }) => theme.colors.valueLoss}33;
                color: ${({ theme }) => theme.colors.valueLoss}cc;
            }
        }
        &.major {
            color: white;
            font-weight: 700;
            &.positive {
                // background-color: ${({ theme }) => theme.colors.valueGain}77;
                color: ${({ theme }) => theme.colors.valueGain};
            }
            &.negative {
                // background-color: ${({ theme }) => theme.colors.valueLoss}77;
                color: ${({ theme }) => theme.colors.valueLoss};
            }
        }
    }
`
