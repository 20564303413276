import firebase from 'firebase/app'
import { useEffect, useState } from 'react'

import { IHoldingsPerformance } from '../models'

export const useHoldingPerformance = () => {
    const [performance, setPerformance] = useState<IHoldingsPerformance | null>(null)
    const [error, setError] = useState<any>(null)
    const [loading, setLoading] = useState(true)

    const fetch = () => {
        if (!firebase.auth().currentUser) {
            return
        }
        console.log('fetching performance data...')
        setLoading(true)
        const fetchHoldingPerformance = firebase
            .functions()
            .httpsCallable('fetchHoldingPerformance')
        return fetchHoldingPerformance().then(
            (result) => {
                const perf = result.data as IHoldingsPerformance
                console.log(JSON.stringify(perf))
                setPerformance(perf)
                setLoading(false)
            },
            (err) => {
                console.error('failed to retrieve performance data from API', err)
                setError(err)
                setLoading(false)
            },
        )
    }

    useEffect(() => {
        fetch()
        // setPerformance({ "AAPL": { "3 month": 261.96, "5 day": 320.03, "1 year": 170.89, "10 year": 28.63, "1 day": 319.61, "6 month": 200.48, "2 week": 324.34, "5 year": 126.46, "2 year": 162.71, "1 month": 316.96, "3 day": 321.55, "3 year": 133.29, "9 month": 185.72 }, "VTI": { "1 day": 170.42, "10 year": 54.77, "5 day": 168.71, "2 week": 166.06, "3 month": 157.12, "5 year": 107.96, "2 year": 136.02, "1 year": 141, "6 month": 147.08, "9 month": 143.8, "1 month": 166.59, "3 year": 120.14, "3 day": 169.95 }, "VXUS": { "6 month": 49.89, "5 day": 54.93, "2 year": 56.4, "1 day": 55.44, "5 year": 50.14, "1 year": 50.59, "2 week": 54.9, "3 month": 54.1, "3 day": 55.04, "9 month": 50.68, "3 year": 48.59, "1 month": 56.3 }, "IXUS": { "2 week": 61.04, "5 day": 61.07, "6 month": 55.42, "5 year": 54.98, "2 year": 62.59, "1 year": 56.34, "3 month": 60.42, "1 day": 61.77, "3 day": 61.21, "3 year": 53.48, "9 month": 56.55, "1 month": 62.66 }, "FSKAX": { "3 month": 87.81, "1 year": 78.04, "6 month": 81.78, "5 year": 60.98, "5 day": 93.27, "2 week": 91.81, "2 year": 75.75, "1 day": 94.21, "1 month": 92.19, "9 month": 79.63, "3 day": 93.97, "3 year": 67.27 }, "FSGGX": { "1 year": 12.17, "5 year": 11.89, "3 month": 13.29, "1 day": 13.38, "2 week": 13.25, "5 day": 13.27, "2 year": 13.38, "6 month": 12.2, "9 month": 12.25, "3 year": 11.49, "3 day": 13.27, "1 month": 13.56 }, "VTSAX": { "1 day": 82.93, "10 year": 26.65, "6 month": 71.59, "3 month": 76.47, "2 year": 66.21, "1 year": 68.64, "5 year": 52.56, "2 week": 80.8, "5 day": 82.11, "3 day": 82.73, "3 year": 58.47, "1 month": 81.11, "9 month": 69.98 }, "FXNAX": { "3 month": 11.87, "1 day": 12.1, "2 week": 12.1, "1 year": 11.35, "6 month": 11.99, "5 day": 12.11, "2 year": 11.32, "5 year": 11.81, "3 day": 12.12, "1 month": 11.96, "9 month": 11.54, "3 year": 11.51 } })
        // setLoading(false)
    }, [])

    return {
        loading,
        performance,
        error,
    }
}
