import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { getMonthlyBalance, IAccountHistory, IConfig } from '../../models'
import AccountRow from './AccountRow'

interface IAccountRowsProps {
    config: IConfig
    accountHistories: IAccountHistory[]
    isLiabilities?: boolean
}

const AccountRows: React.FC<IAccountRowsProps> = (props) => {
    const { config, accountHistories, isLiabilities } = props

    const filteredAccountHistories = _.filter(accountHistories, (accountHistory) => {
        const currentBalance = getMonthlyBalance(
            accountHistory.history,
            moment(config.endingDate),
        )
        return !!currentBalance || config.showZeroBalanceAccounts
    })
    if (config.aggregatesOnly) {
        return null
    }
    // hide rows for singular accounts
    if (
        !filteredAccountHistories.length ||
        (!config.showSingularCategoryAccounts && filteredAccountHistories.length === 1)
    ) {
        return null
    }

    // sort by most recent balance
    let sortedHistories = _.sortBy(filteredAccountHistories, (accountHistory) => {
        const lastSnapshot = _.last(accountHistory.history)
        if (!lastSnapshot) {
            return 0
        }
        return lastSnapshot.balance
    })

    if (!isLiabilities) {
        sortedHistories = _.reverse(sortedHistories)
    }

    return (
        <>
            {sortedHistories.map((accountHistory, accountIndex) => (
                <AccountRow
                    key={accountIndex}
                    config={config}
                    accountHistory={accountHistory}
                />
            ))}
        </>
    )
}

export default AccountRows
