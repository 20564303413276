import _, { noop } from 'lodash'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import Error from '../components/Error'
import Loading from '../components/Loading'
import Menubar from '../components/Menubar'
import { useAccountHistoryData } from '../hooks/useAccountHistoryData'
import useConfig from '../hooks/useConfig'
import { useCurrentBalances } from '../hooks/useCurrentBalances'
import { useHoldingQuotes } from '../hooks/useHoldingQuotes'
import { useHoldings } from '../hooks/useHoldings'
import {
    IAccountsHistory,
    IConfig,
    IConfigOptions,
    ICurrentBalances,
    ICurrentHoldings,
    ICurrentQuotes,
} from '../models'
import Dashboard from './Dashboard'
import NetWorthTable from './NetWorthTable'
import Performance from './Performance'
import Rebalancing from './Rebalancing'

const MainWrapper = styled.div`
    height: 100%;
`

interface IAppContext {
    config?: IConfig | null | undefined
    updateConfig: (updates: Partial<IConfigOptions>) => void

    currents?: ICurrentBalances | null | undefined
    currentsLoading: boolean
    refreshCurrents: () => Promise<void>

    history?: IAccountsHistory | null | undefined
    historyLoading: boolean
    refreshHistory: () => Promise<void>

    quotes?: ICurrentQuotes | null | undefined
    quotesLoading: boolean
    refreshQuotes: () => Promise<void>

    holdings?: ICurrentHoldings | null | undefined
    holdingsLoading: boolean
    refreshHoldings: () => Promise<void>
}

const defaultContext: IAppContext = {
    config: null,
    updateConfig: () => {
        noop()
    },

    currents: null,
    currentsLoading: false,
    refreshCurrents: () => Promise.resolve(),

    history: null,
    historyLoading: false,
    refreshHistory: () => Promise.resolve(),

    quotes: null,
    quotesLoading: false,
    refreshQuotes: () => Promise.resolve(),

    holdings: null,
    holdingsLoading: false,
    refreshHoldings: () => Promise.resolve(),
}

export const MainContext = React.createContext(defaultContext)

const Main: React.FC = () => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const { currentConfig, updateOptions } = useConfig()
    const [adjustedHistory, setAdjustedHistory] = useState<IAccountsHistory | null>(null)
    const {
        history,
        loading: historyLoading,
        refresh: refreshHistory,
    } = useAccountHistoryData()
    const {
        currents,
        loading: currentsLoading,
        refresh: refreshCurrents,
    } = useCurrentBalances()
    const { quotes, loading: quotesLoading, refresh: refreshQuotes } = useHoldingQuotes()
    const { holdings, loading: holdingsLoading, refresh: refreshHoldings } = useHoldings()

    // adjust current balances
    useEffect(() => {
        // apply current balances
        if (history) {
            if (!!currents && currents.responses) {
                currents.responses.forEach((response) => {
                    response.accounts.forEach((account) => {
                        const matchedAccount = _.find(
                            history.accounts,
                            (acct) =>
                                acct.account.plaidInstitutionId ===
                                    response.item.institution_id &&
                                acct.account.plaidAccountName === account.name,
                        )
                        if (matchedAccount != null) {
                            // console.log('found matched account!', matchedAccount, account.balances.adjusted)
                            matchedAccount.account.currentBalance =
                                account.balances.adjusted
                        }
                    })
                })
            }
            console.log('setting adjusted history', history)
            setAdjustedHistory(history)
        }
    }, [history, currents])

    const context: IAppContext = {
        config: currentConfig,
        updateConfig: updateOptions,
        currents,
        currentsLoading,
        refreshCurrents,
        history: adjustedHistory,
        historyLoading,
        refreshHistory,
        quotes,
        quotesLoading,
        refreshQuotes,
        holdings,
        holdingsLoading,
        refreshHoldings,
    }

    if (!currentConfig) {
        return <Loading message="Loading config..." />
    }

    if (historyLoading && isFirstLoad) {
        return <Loading message="Fetching data..." />
    }

    if (!adjustedHistory) {
        return <Error message="Failed to fetch data." />
    }

    if (isFirstLoad) {
        setIsFirstLoad(false)
    }

    return (
        <Router>
            <MainContext.Provider value={context}>
                <MainWrapper>
                    <Menubar />
                    <Switch>
                        <Route path="/history">
                            <NetWorthTable />
                        </Route>
                        <Route path="/rebalancing">
                            <Rebalancing />
                        </Route>
                        <Route path="/performance">
                            <Performance />
                        </Route>
                        <Route path="/">
                            <Dashboard />
                        </Route>
                    </Switch>
                </MainWrapper>
            </MainContext.Provider>
        </Router>
    )
}

export default Main
