import firebase from 'firebase/app'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { ICurrentBalances } from '../models'

export const useCurrentBalances = () => {
    const [currents, setCurrents] = useState<ICurrentBalances | null>(null)
    const [error, setError] = useState<any>(null)
    const [loading, setLoading] = useState(true)

    const fetchCurrents = () => {
        if (!firebase.auth().currentUser) {
            return
        }
        setLoading(true)
        const uid = firebase.auth().currentUser!.uid
        const currentsDoc = firebase
            .firestore()
            .collection('currents')
            .doc(uid)
            .collection('snapshots')
            .doc('current')

        currentsDoc.onSnapshot(
            (snapshot) => {
                if (snapshot.exists && snapshot.data() && !_.isEmpty(snapshot.data())) {
                    console.log('fetched new currents from firestore', snapshot.data())
                    setCurrents(snapshot.data() as ICurrentBalances)
                    setLoading(false)
                }
            },
            (err) => {
                console.error('failed to retrieve currents from firestore', err)
                setError(err)
                setLoading(false)
            },
        )
    }

    const refresh = () => {
        console.log('refreshing currents...')
        setLoading(true)
        const refreshCurrents = firebase.functions().httpsCallable('refreshCurrents')
        return refreshCurrents().then(
            (result) => {
                const currents = result.data as ICurrentBalances
                setCurrents(currents)
                setLoading(false)
            },
            (err) => {
                console.error('failed to retrieve currents from API', err)
                setError(err)
                setLoading(false)
            },
        )
    }

    useEffect(() => {
        fetchCurrents()
    }, [])

    return {
        loading,
        currents,
        error,
        refresh,
    }
}
