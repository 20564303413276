import { H3, ProgressBar } from '@blueprintjs/core'
import React from 'react'
import styled from 'styled-components'

const LoadingWrapper = styled.div`
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .progress {
        width: 400px;
    }
`

interface IProps {
    message?: string
}

const Loading: React.FC<IProps> = (props) => {
    const { message } = props

    return (
        <LoadingWrapper>
            <ProgressBar intent="primary" className="progress" value={1} />
            <div style={{ marginTop: 40 }}>
                <H3>{message}</H3>
            </div>
        </LoadingWrapper>
    )
}

export default Loading
