import { PieDatumWithColor, ResponsivePie } from '@nivo/pie'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React from 'react'

import {
    getAccountClassificationColor,
    getAccountColor,
    getAccountTypeColor,
    getInstitutionColor,
} from '../../lib/colors'
import {
    AccountCategory,
    getMonthlyBalance,
    IAccountHistory,
    IAccountsHistory,
    IConfig,
} from '../../models'

export enum AccountsPieType {
    ByAccount,
    ByType,
    ByInstitution,
    ByClassification,
}

interface IPieProps {
    history: IAccountsHistory
    config: IConfig
    type: AccountsPieType
}

// pallete generator -- http://tristen.ca/hcl-picker/#/hlc/11/1.05/154837/6F3742
// const colors = ['#1F644D','#166456','#11635E','#156265','#20606C','#2D5E70','#3B5B73','#495874','#565573','#625170','#6D4D6B']
// const colors = ['#393849','#353F4F','#304553','#2B4C56','#265257','#245857','#265E55','#2B6351','#35684D','#406D48','#4D7142'].reverse()

const getCurrentBalance = (account: IAccountHistory): number => {
    return !_.isNil(account.account.currentBalance)
        ? account.account.currentBalance
        : getMonthlyBalance(account.history, moment())
}

const byAccount = (history: IAccountsHistory): PieDatumWithColor[] => {
    const otherAggregation: PieDatumWithColor = {
        id: 'Other Accounts',
        label: 'Other Accounts',
        value: 0,
        color: '#B7B7B7',
    }

    const pieSlices = _.chain(history.accounts)
        .filter((account) => {
            return (
                account.account.category === AccountCategory.Asset &&
                getCurrentBalance(account) !== 0
            )
        })
        .map((account) => {
            return {
                id: account.account.name,
                label: account.account.name,
                value: getCurrentBalance(account),
                color: account.account.color || getAccountColor(account.account),
            } as PieDatumWithColor
        })
        .orderBy(
            (pieData) => {
                return pieData.value
            },
            ['desc'],
        )
        .map((pieData) => {
            if (pieData.value > 20000) {
                return pieData
            }
            otherAggregation.value += pieData.value
            return null
        })
        .compact()
        .value()
    if (otherAggregation.value > 0) {
        pieSlices.push(otherAggregation)
    }
    return pieSlices
}

const byType = (history: IAccountsHistory): PieDatumWithColor[] => {
    return _.chain(history.accounts)
        .filter((account) => {
            return (
                account.account.category === AccountCategory.Asset &&
                getCurrentBalance(account) !== 0
            )
        })
        .groupBy((account) => {
            return account.account.type
        })
        .map((accounts, type) => {
            return {
                id: type,
                label: type,
                value: _.sumBy(accounts, (account) => {
                    return getCurrentBalance(account)
                }),
                color: accounts.length
                    ? getAccountTypeColor(accounts[0].account.type)
                    : null,
            } as PieDatumWithColor
        })
        .orderBy(
            (account) => {
                return account.value
            },
            ['desc'],
        )
        .value()
}

const byClassification = (history: IAccountsHistory): PieDatumWithColor[] => {
    return _.chain(history.accounts)
        .filter((account) => {
            return (
                account.account.category === AccountCategory.Asset &&
                getCurrentBalance(account) !== 0 &&
                !!account.account.classification
            )
        })
        .groupBy((account) => {
            return account.account.classification
        })
        .map((accounts, type) => {
            return {
                id: type,
                label: type,
                value: _.sumBy(accounts, (account) => {
                    return getCurrentBalance(account)
                }),
                color: accounts.length
                    ? getAccountClassificationColor(accounts[0].account.classification!)
                    : null,
            } as PieDatumWithColor
        })
        .orderBy(
            (account) => {
                return account.value
            },
            ['desc'],
        )
        .value()
}

const byInstitution = (history: IAccountsHistory): PieDatumWithColor[] => {
    return _.chain(history.accounts)
        .filter((account) => {
            return (
                account.account.category === AccountCategory.Asset &&
                getCurrentBalance(account) !== 0
            )
        })
        .groupBy((account) => {
            return account.account.institution
        })
        .map((accounts, institution) => {
            return {
                id: institution,
                label: institution,
                value: _.sumBy(accounts, (account) => {
                    return getCurrentBalance(account)
                }),
                color: accounts.length ? getInstitutionColor(accounts[0].account) : null,
            } as PieDatumWithColor
        })
        .orderBy(
            (account) => {
                return account.value
            },
            ['desc'],
        )
        .value()
}

const AccountsPie: React.FC<IPieProps> = ({ history, config, type }) => {
    let data: PieDatumWithColor[] = []
    switch (type) {
        case AccountsPieType.ByAccount:
            data = byAccount(history)
            break
        case AccountsPieType.ByType:
            data = byType(history)
            break
        case AccountsPieType.ByInstitution:
            data = byInstitution(history)
            break
        case AccountsPieType.ByClassification:
            data = byClassification(history)
            break
    }
    const totalValue = _.sumBy(data, (d) => d.value)

    return (
        <ResponsivePie
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            innerRadius={0.4}
            padAngle={0.2}
            cornerRadius={0}
            colors={(data) => {
                return _.toString(data.color)
            }}
            borderWidth={0}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            radialLabelsSkipAngle={9}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#ffffffdd"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={10}
            radialLabelsLinkHorizontalLength={12}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsSkipAngle={25}
            slicesLabelsTextColor="#ffffffdd"
            animate={true}
            // sortByValue={true}
            motionStiffness={90}
            motionDamping={15}
            sliceLabel={(label) => {
                return numeral(label.value).format(config.currencyFormat)
            }}
            theme={{
                labels: {
                    text: {
                        fontSize: 14,
                    },
                },
                tooltip: {
                    container: {
                        backgroundColor: '#111111dd',
                        padding: 0,
                    },
                },
            }}
            tooltip={({ color, id, value }) => {
                const amount = numeral(value).format(config.currencyFormat)
                const percentTotal = numeral(value / totalValue).format(
                    config.percentFormat,
                )
                return (
                    <div
                        style={{
                            backgroundColor: '#111111dd',
                            fontSize: 16,
                            color,
                            padding: 14,
                        }}
                    >
                        <div
                            style={{
                                fontSize: 14,
                                fontWeight: 700,
                                color: 'white',
                                opacity: 0.55,
                            }}
                        >
                            {id}
                        </div>
                        <div style={{ fontSize: 27, fontWeight: 400 }}>{amount}</div>
                        <div style={{ fontSize: 15, fontWeight: 400, color: 'white' }}>
                            {percentTotal}
                        </div>
                    </div>
                )
            }}
            tooltipFormat={(value) => {
                return numeral(value).format(config.currencyFormat)
            }}
            // legends={[
            //     {
            //         anchor: 'right',
            //         direction: 'column',
            //         translateY: 0,
            //         translateX: 75,
            //         itemWidth: 130,
            //         itemHeight: 18,
            //         itemTextColor: '#ffffff66',
            //         symbolSize: 13,
            //         symbolShape: 'circle',
            //         effects: [
            //             {
            //                 on: 'hover',
            //                 style: {
            //                     itemTextColor: '#fff'
            //                 }
            //             }
            //         ]
            //     }
            // ]}
        />
    )
}

export default AccountsPie
