const foreground = '#f1f5f9'

export const colors = {
    background: '#0f172a', //'#30404D',
    foreground: `${foreground}ee`,
    foregroundOpacity: (opacity: string) => {
        return `${foreground}${opacity}`
    },

    netWorthBackground: '#6ee7b7cc', //'#d5af5c',
    netWorthCurrentBackground: '#6ee7b7aa',

    menuBar: '#475569aa',
    categoryHeading: '#334155aa', //'#5440558c',
    typeHeading: '#33415555', // '#4d385066',

    valueGain: '#6ee7b7', // '#92da92', //'#72c172',
    valueLoss: '#fb7185', // '#f7575a', //'#ff696c',
    valueZero: '#ffffff88',
}

export const weights = {
    lightest: 100,
    lighter: 200,
    light: 300,
    normal: 400,
    semiBold: 600,
    bold: 700,
    bolder: 800,
    boldest: 900,
}

export const fonts = {
    Primary: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        lineHeight: 'normal',
    },
}

// using rem's to make all sizes density independent.
// fxx refers to their equivalent in px so f12 = 12px
export const sizes = {
    f12: '0.75rem',
    f13: '0.8125rem',
    f14: '0.875rem',
    f15: '0.9375rem',
    f16: '1rem',
    f17: '1.0625rem',
    f18: '1.125rem',
    f19: '1.188rem',
    f20: '1.25rem',
    f22: '1.375rem',
    f25: '1.5625rem',
    f30: '1.875rem',
    f32: '2rem',
    f40: '2.5rem',
    f44: '2.75rem',
    f94: '5.875rem',
}

export const borders = {
    none: 0,
    thin: '1px solid',
    medium: '2px solid',
    thick: '4px solid',
    thickest: '8px solid',
}

export const radiuses = {
    none: 0,
    small: '3px',
    medium: '5px',
    large: '6rem',
}

//to be used in rems so, .25rems = 4px
export const gridFactor = 0.25

const theme = {
    borders,
    colors,
    radiuses,
    fonts,
    gridFactor,
    sizes,
    weights,
}

export default theme
