import firebase from 'firebase/app'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { IAccountsHistory } from '../models'

export const useAccountHistoryData = () => {
    const [history, setHistory] = useState<IAccountsHistory | null>(null)
    const [error, setError] = useState<any>(null)
    const [loading, setLoading] = useState(true)

    const fetchHistory = () => {
        if (!firebase.auth().currentUser) {
            return
        }
        setLoading(true)
        const uid = firebase.auth().currentUser!.uid
        const currentHistoryDoc = firebase
            .firestore()
            .collection('history')
            .doc(uid)
            .collection('snapshots')
            .doc('current')

        currentHistoryDoc.onSnapshot(
            (snapshot) => {
                if (snapshot.exists && snapshot.data() && !_.isEmpty(snapshot.data())) {
                    console.log('fetched new history from firestore', snapshot.data())
                    setHistory(snapshot.data() as IAccountsHistory)
                    setLoading(false)
                    return
                }
            },
            (err) => {
                console.error('failed to retrieve history from firestore', err)
                setError(err)
                setLoading(false)
            },
        )
    }

    const refresh = () => {
        console.log('refreshing history...')
        setLoading(true)
        const refreshHistory = firebase.functions().httpsCallable('refreshHistory')
        return refreshHistory().then(
            (result) => {
                const history: IAccountsHistory = result.data
                setHistory(history)
                setLoading(false)
            },
            (err) => {
                console.error('failed to retrieve history from API', err)
                setError(err)
                setLoading(false)
            },
        )
    }

    useEffect(() => {
        fetchHistory()
    }, [])

    return {
        loading,
        history,
        error,
        refresh,
    }
}
