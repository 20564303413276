import { HTMLTable, Popover } from '@blueprintjs/core'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, { useCallback, useMemo } from 'react'
import {
    Area,
    AreaChart,
    CartesianGrid,
    Label,
    ReferenceLine,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import styled from 'styled-components'

import { investmentReturn, simpleReturn } from '../../lib/finance'
import {
    getMonthlyBalance,
    getMonthlySnapshot,
    getTimePeriods,
    IBalanceSnapshot,
    IConfig,
    ITimePeriodConfiguration,
    ReturnCalculationType,
    timePeriodConfigurations,
} from '../../models'
import CurrencyValue from './CurrencyValue'
import PercentValue from './PercentValue'

const BalancePopoverWrapper = styled.div`
    background-color: #334155;
    padding: 20px;

    .row {
        display: flex;
        flex-direction: row;

        &.title {
            font-size: 17px;

            .label {
                margin-top: 10px;
                font-size: 22px;
            }

            .month {
                flex: 1;
                color: #5599dd;
                text-align: center;
                font-size: 13px;
                opacity: 0.65;
                font-weight: 700;
            }

            .value {
                font-size: 22px;
                font-weight: 700;
                color: #5599dd;
            }
        }

        .contributions {
            margin-right: 15px;
            .value,
            .month {
                color: #5599ddaa;
            }
        }

        &.spacing {
            margin-top: 14px;
        }

        &.dimmed {
            opacity: 0.5;
        }
    }

    .gain-loss,
    .row {
        .heading {
            // font-size: 16px;
            opacity: 0.4;
            font-weight: 700;
            margin-bottom: 6px;

            &.right {
                text-align: right;
            }
        }

        .label {
            margin-right: 16px;
            white-space: nowrap;
            flex: 1;

            &.month {
                opacity: 0.4;
            }
        }

        .value {
            white-space: nowrap;
            flex: 1;
            text-align: right;

            &.percent {
                margin-left: 10px;
            }

            &.zero {
                color: ${({ theme }) => theme.colors.valueZero};
            }
            &.percent.positive {
                color: ${({ theme }) => theme.colors.valueGain};
            }
            &.negative {
                color: ${({ theme }) => theme.colors.valueLoss};
            }
        }
    }
    .gain-loss {
        padding-top: 20px;
        width: 100%;

        tr.current {
            td {
                color: #ffd756;

                .month {
                    opacity: 1;
                }
            }
        }
    }
    .row {
        .heading {
            margin-left: 11px;
        }
    }

    .chart {
        .current-month-label {
            tspan {
                stroke: #ffd756;
                fill: #ffd756;
                text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.6);
            }
        }
    }
`

const ChartTooltipContainer = styled.div`
    background-color: #333333cc;
    padding: 10px;
    color: #eee;

    .date {
        font-size: 13px;
        opacity: 0.7;
    }
    .balance {
        font-weight: 700;
        font-size: 20px;
    }
`

interface IChartTooltipProps {
    active?: boolean
    payload?: any
    config: IConfig
}
const ChartTooltip: React.FC<IChartTooltipProps> = (props) => {
    const { active, payload, config } = props
    if (!active) {
        return null
    }

    const { mom, balance } = payload[0].payload
    return (
        <ChartTooltipContainer>
            <div className="date">{mom.format(config.monthFormat)}</div>
            <div className="balance">
                {numeral(balance).format(config.currencyFormat)}
            </div>
        </ChartTooltipContainer>
    )
}

interface IBalanceProps {
    config: IConfig
    month: moment.Moment
    title: string
    showGainLoss?: boolean
    balanceHistory: IBalanceSnapshot[]
    isCurrent?: boolean
    isLiability?: boolean
    exactBalance?: number | null
    returnCalculationType: ReturnCalculationType
}

const Balance: React.FC<IBalanceProps> = (props) => {
    const {
        config,
        month,
        title,
        balanceHistory,
        isCurrent,
        showGainLoss,
        isLiability,
        exactBalance,
        returnCalculationType,
    } = props
    const currentSnapshot = getMonthlySnapshot(balanceHistory, month)
    const currentBalance = _.isNil(exactBalance)
        ? currentSnapshot
            ? currentSnapshot.balance
            : 0
        : exactBalance
    const currentContributions = currentSnapshot ? currentSnapshot.contribution : 0

    const getDiffBalance = useCallback(
        (
            timePeriodConfiguration: ITimePeriodConfiguration | null | undefined,
        ): number | null => {
            if (!timePeriodConfiguration) {
                return null
            }
            const start = timePeriodConfiguration.getStartDate(month)
            let diffBalance = getMonthlyBalance(balanceHistory, start)
            // can't start with zero balance, fast forward until there's a balance
            if (!diffBalance) {
                _.each(balanceHistory, (snapshot) => {
                    if (snapshot.balance) {
                        // startDate = moment(`${snapshot.month}/1/${snapshot.year}`, 'M/D/YYYY').endOf('M')
                        diffBalance = snapshot.balance
                        return false
                    }
                })
            }
            return diffBalance
        },
        [balanceHistory, month],
    )

    const ratesOfReturn = useMemo(() => {
        const periods = _.compact(
            (config.selectedTimePeriodLabels || []).map((selectedLabel) => {
                return _.find(
                    timePeriodConfigurations,
                    (period) => period.label === selectedLabel,
                )
            }),
        )
        return periods.map((period) => {
            if (returnCalculationType === ReturnCalculationType.Simple) {
                return simpleReturn(getDiffBalance(period), currentBalance)
            }
            return investmentReturn(
                month,
                currentBalance,
                balanceHistory,
                period.getStartDate(month),
            )
        })
    }, [
        config.selectedTimePeriodLabels,
        // config.timePeriodConfigurations,
        returnCalculationType,
        currentBalance,
        month,
        balanceHistory,
        getDiffBalance,
    ])

    // const rateOfReturn = useMemo(() => {
    //     if (!config.timePeriodConfiguration) {
    //         return null;
    //     }
    //     if (returnCalculationType === ReturnCalculationType.Simple) {
    //         return simpleReturn(
    //             getDiffBalance(config.timePeriodConfiguration),
    //             currentBalance
    //         );
    //     }
    //     return investmentReturn(
    //         month,
    //         currentBalance,
    //         balanceHistory,
    //         config.timePeriodConfiguration.getStartDate(month)
    //     );
    // }, [
    //     config.timePeriodConfiguration,
    //     returnCalculationType,
    //     currentBalance,
    //     month,
    //     balanceHistory,
    //     getDiffBalance,
    // ]);

    // const altRateOfReturn = useMemo(() => {
    //     if (!config.altTimePeriodConfiguration) {
    //         return null;
    //     }
    //     if (returnCalculationType === ReturnCalculationType.Simple) {
    //         return simpleReturn(
    //             getDiffBalance(config.altTimePeriodConfiguration),
    //             currentBalance
    //         );
    //     }
    //     return investmentReturn(
    //         month,
    //         currentBalance,
    //         balanceHistory,
    //         config.altTimePeriodConfiguration.getStartDate(month)
    //     );
    // }, [
    //     config.altTimePeriodConfiguration,
    //     returnCalculationType,
    //     currentBalance,
    //     month,
    //     balanceHistory,
    //     getDiffBalance,
    // ]);

    const BalancePopover = () => {
        const timePeriods = getTimePeriods(month)

        const balanceHistoryWithMoments = balanceHistory.map((h) => ({
            mom: moment(`${h.month}/1/${h.year}`, 'M/D/YYYY'),
            date: moment(`${h.month}/1/${h.year}`, 'M/D/YYYY').format('M/YY'),
            balance: h.balance,
        }))

        const strokeColor = !isLiability ? '#5599dd' : '#ff696c'
        return (
            <BalancePopoverWrapper>
                <div className="row title">
                    <div className="label">{title}</div>
                    {!!currentContributions && (
                        <div className="balance contributions">
                            <CurrencyValue
                                config={config}
                                value={currentContributions || 0}
                            />
                            <div className="month">Contrib.</div>
                        </div>
                    )}
                    <div className="balance">
                        <CurrencyValue config={config} value={currentBalance} />
                        <div className="month">{month.format(config.monthFormat)}</div>
                    </div>
                </div>

                <div className="row">
                    <HTMLTable
                        className="gain-loss spacing"
                        style={{ width: 450 }}
                        condensed
                    >
                        <thead>
                            <tr>
                                <td className="heading">Period</td>
                                <td className="heading">Start</td>
                                <td className="heading right">Balance</td>
                                <td className="heading right">Contr.</td>
                                <td className="heading right">G/L</td>
                            </tr>
                        </thead>
                        <tbody>
                            {timePeriods.map((period) => {
                                const periodStartBalance = getMonthlyBalance(
                                    balanceHistory,
                                    period.date,
                                )
                                const periodContributions = _.sumBy(
                                    balanceHistory,
                                    (snapshot) => {
                                        const date = moment(
                                            `${snapshot.month}/1/${snapshot.year}`,
                                            'M/D/YYYY',
                                        )
                                        if (
                                            date.isBetween(period.date, month, 'M', '(]')
                                        ) {
                                            return snapshot.contribution || 0
                                        }
                                        return 0
                                    },
                                )
                                let rateOfReturn: number | null = null
                                if (
                                    returnCalculationType === ReturnCalculationType.Simple
                                ) {
                                    rateOfReturn = simpleReturn(
                                        periodStartBalance,
                                        currentBalance,
                                    )
                                } else {
                                    rateOfReturn = investmentReturn(
                                        month,
                                        currentBalance,
                                        balanceHistory,
                                        period.date,
                                    )
                                }
                                const isCurrentPeriod =
                                    !!config.selectedTimePeriodLabels.find(
                                        (l) => l === period.label,
                                    )
                                const className = isCurrentPeriod ? 'current' : ''
                                // let className = config.timePeriodConfiguration.label === period.label ? 'current' : ''
                                // if (!!config.altTimePeriodConfiguration && config.altTimePeriodConfiguration.label === period.label) {
                                //     className = 'current'
                                // }
                                return (
                                    <tr key={period.label} className={className}>
                                        <td>
                                            <div className="label">{period.label}</div>
                                        </td>
                                        <td>
                                            <div className="label month">
                                                {moment(period.date).format(
                                                    config.monthFormat,
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <CurrencyValue
                                                config={config}
                                                value={periodStartBalance}
                                            />
                                        </td>
                                        <td>
                                            <CurrencyValue
                                                config={config}
                                                value={periodContributions}
                                            />
                                        </td>
                                        <td>
                                            <PercentValue
                                                config={config}
                                                value={rateOfReturn}
                                                isLiability={isLiability}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </HTMLTable>

                    <div>
                        <div
                            className="row spacing"
                            style={{ marginTop: 26, justifyContent: 'center' }}
                        >
                            <div className="heading center">All Time Trend</div>
                        </div>
                        <AreaChart
                            className="chart"
                            width={400}
                            height={250}
                            data={balanceHistoryWithMoments}
                            margin={{ left: 0, right: 30, top: 10 }}
                        >
                            <Area
                                type="natural"
                                dataKey="balance"
                                strokeWidth={2}
                                stroke={strokeColor}
                                fill={`${strokeColor}33`}
                            />
                            <XAxis stroke="#ffffff77" dataKey="date" />
                            <YAxis
                                stroke="#ffffff77"
                                scale="linear"
                                domain={['dataMin', isLiability ? 0 : 'auto']}
                                tickFormatter={(val) => numeral(val).format('(0a)')}
                            />
                            <CartesianGrid stroke="#ffffff1a" strokeDasharray="1 1" />
                            <ReferenceLine
                                x={month.format('M/YY')}
                                stroke="#FFD756cc"
                                strokeDasharray="2 2"
                            >
                                <Label fontSize={16} className="current-month-label">
                                    {month.format(config.monthFormat)}
                                </Label>
                            </ReferenceLine>
                            {isLiability && (
                                <ReferenceLine
                                    y={0}
                                    stroke={strokeColor}
                                    strokeDasharray="3 3"
                                />
                            )}
                            <Tooltip content={<ChartTooltip config={config} />} />
                        </AreaChart>
                    </div>
                </div>
            </BalancePopoverWrapper>
        )
    }

    const BalancePopoverContainer = (
        <div>
            <BalancePopover />
        </div>
    )

    // const additionalRatesOfReturn = ratesOfReturn.slice(1);
    // console.log("additionalRatesOfReturn", additionalRatesOfReturn);
    return (
        <td className={`${isCurrent ? 'current' : ''}`}>
            <Popover
                lazy={true}
                interactionKind="click"
                hoverOpenDelay={150}
                hoverCloseDelay={150}
                content={BalancePopoverContainer}
            >
                <>
                    <div
                        className={`balance
                    ${currentContributions ? 'with-contrib' : ''} 
                    ${
                        !currentBalance
                            ? 'zero'
                            : currentBalance < 0
                            ? 'negative'
                            : 'positive'
                    } 
                    ${
                        !_.isNil(exactBalance) ||
                        (!!currentSnapshot && currentSnapshot.final) ||
                        !currentSnapshot
                            ? 'final'
                            : 'non-final'
                    }`}
                    >
                        <CurrencyValue config={config} value={currentBalance} />
                    </div>
                    {/* {showGainLoss && (
                        <div className={`diff`}>
                            <PercentValue
                                config={config}
                                value={ratesOfReturn[0]}
                                isLiability={isLiability}
                            />
                        </div>
                    )} */}
                    {showGainLoss &&
                        ratesOfReturn.map((rate, i) => (
                            <div className={'diff'} key={i}>
                                <PercentValue
                                    config={config}
                                    value={rate}
                                    isLiability={isLiability}
                                />
                            </div>
                        ))}
                </>
            </Popover>
        </td>
    )
}

export default Balance
