import _ from 'lodash'
import moment from 'moment'

export enum AccountCategory {
    Asset = 'Assets',
    Liability = 'Liabilities',
}
export const AccountCategories = [AccountCategory.Asset, AccountCategory.Liability]

export type CurrentBalanceAdjusterFunction = (
    currentBalance: number,
    accountHistories: IAccountHistory[],
) => number

export enum AccountType {
    Cash = 'Cash',
    Investment = 'Investments',
    Property = 'Property',
    CreditCard = 'Credit Cards',
    Loan = 'Loans',
}
export const AccountTypes = [
    AccountType.Investment,
    AccountType.Cash,
    AccountType.Property,
    AccountType.CreditCard,
    AccountType.Loan,
]

export enum AccountClassification {
    Depository = 'Depository',
    Taxable = 'Taxable',
    Retirement = 'Retirement',
    Property = 'Property',
    Credit = 'Credit',
}

export enum HoldingVehicle {
    MutualFund = 'Mutual Fund',
    ETF = 'ETF',
    Stock = 'Stock',
    Cash = 'Cash',
}

export enum ReturnCalculationType {
    Simple,
    MoneyWeighted,
}

export interface IAccountHolding {
    symbol: string
    shares: number
    costBasis: number
}

export interface IAccount {
    name: string
    category: AccountCategory
    classification?: AccountClassification | null
    type: AccountType
    institution: string
    plaidInstitutionId?: string | null
    plaidAccountName?: string | null
    currentBalance: number | null | undefined
    currentBalanceAdjuster?: CurrentBalanceAdjusterFunction
    returnType: ReturnCalculationType
    holdings?: IAccountHolding[]
    color?: string
}

export interface IBalanceSnapshot {
    month: number
    year: number
    balance: number
    contribution?: number
    asOfTimestamp?: string
    final?: boolean
}

export interface IAccountHistory {
    account: IAccount
    history: IBalanceSnapshot[]
}

export interface IAccountsHistory {
    accounts: IAccountHistory[]
    updated: string
}

export type CurrentAccountType = 'depository' | 'investment' | 'credit' | 'other'

export type CurrentAccountSubType =
    | 'checking'
    | 'brokerage'
    | '401k'
    | 'roth'
    | 'savings'
    | 'ira'
    | 'credit card'
    | 'cash management'

export interface ICurrentBalance {
    available: number | null
    current: number
    adjusted: number
    iso_currency_code: string
    limit: number | null
}

export interface ICurrentAccount {
    account_id: string
    mask: string
    name: string
    official_name: string
    balances: ICurrentBalance
    type: CurrentAccountType
    subtype: CurrentAccountSubType
}

export interface ICurrentItem {
    available_products: string[]
    billed_products: string[]
    consent_expiration_time: string | null
    error: Error | null
    institution_id?: string | null
    item_id: string
    products: string[]
    update_type: string
    webhook: string
}

export interface ICurrentBalanceResponse {
    accounts: ICurrentAccount[]
    item: ICurrentItem
    request_id: string
}

export interface ICurrentBalances {
    responses: ICurrentBalanceResponse[]
    updated: string
}

export interface IQuote {
    symbol: string
    price: number
    open: number
    high: number
    low: number
    asOf: string
    previousClose: number
    change: number
    changePercent: number
}

export interface ICurrentQuotes {
    quotes: IQuote[]
    updated: string
}

export interface IEquityStyleSegment {
    value: number
    blend: number
    growth: number
}

export interface IEquityStyle {
    largeCap: IEquityStyleSegment
    midCap: IEquityStyleSegment
    smallCap: IEquityStyleSegment
}

export interface IAssetAllocation {
    usEquity: number
    internationalEquity: number
    fixedIncome: number
    cash: number
    other: number
}

export interface IHolding {
    symbol: string
    vehicle: HoldingVehicle
    expenseRatio: number | null
    equityStyle: IEquityStyle
    assetAllocation: IAssetAllocation
    color?: string
}

export interface ICurrentHoldings {
    holdings: IHolding[]
    updated: string
}

export interface IRebalancingHoldingAdjustment {
    enabled: boolean
    shares: number
    value: number
    factor: number | null
}

export interface IRebalancingAdjustments {
    [symbol: string]: IRebalancingHoldingAdjustment
}

export interface IRebalancingAdjustment {
    name: string
    uid: string
    adjustments: IRebalancingAdjustments
    updated: string
}

export interface IHistoricalQuote {
    asOf: string
    open: number
    close: number
    high: number
    low: number
    volume: number
}

export type PeriodName =
    | '1 day'
    | '3 day'
    | '5 day'
    | '2 week'
    | '1 month'
    | '3 month'
    | '6 month'
    | '9 month'
    | '1 year'
    | '2 year'
    | '3 year'
    | '5 year'
    | '10 year'

export interface IHoldingPerformance {
    [periodName: string]: number
}

export interface IHoldingsPerformance {
    [symbol: string]: IHoldingPerformance
}

export type ClassificationTypes = 'type' | 'institution' | 'classification'

export interface IConfigOptions {
    rateOfReturnLabel: string
    selectedTimePeriodLabels: string[]
    // selectedTimePeriodLabel: string
    // selectedAltTimePeriodLabel: string
    selectedAggregationKey: ClassificationTypes
    showCents: boolean
    reverseMonthOrder: boolean
    visibleMonthCount: number
    showZeroBalanceAccounts: boolean
    showSingularCategoryAccounts: boolean
    aggregatesOnly: boolean
    monthFormat: string
    percentFormat: string
    zeroValue: string
    zeroPercentValue: string
    noGainLossOnCash: boolean
    noGainLossOnLiabilities: boolean
    noGainLossOnProperty: boolean
    endingDate: moment.Moment
}

export interface IConfig extends IConfigOptions {
    readonly timePeriodConfigurations: ITimePeriodConfiguration[]
    // readonly timePeriodConfiguration: ITimePeriodConfiguration
    // readonly altTimePeriodConfiguration?: ITimePeriodConfiguration | null
    readonly allDataMonths: moment.Moment[]
    readonly months: moment.Moment[]
    readonly currencyFormat: string
}

export interface ITimePeriodConfiguration {
    label: string
    getStartDate: (relativeTo: moment.Moment) => moment.Moment
    default?: boolean
}

const timePeriodConfigurations: ITimePeriodConfiguration[] = [
    {
        label: 'YTD',
        getStartDate: (relativeTo) => moment(relativeTo).startOf('y').add(-1, 'M'),
    },
    {
        label: '1 mo',
        getStartDate: (relativeTo) => moment(relativeTo).add(-1, 'M'),
        default: true,
    },
    {
        label: '3 mo',
        getStartDate: (relativeTo) => moment(relativeTo).add(-3, 'M'),
    },
    {
        label: '6 mo',
        getStartDate: (relativeTo) => moment(relativeTo).add(-6, 'M'),
    },
    {
        label: '9 mo',
        getStartDate: (relativeTo) => moment(relativeTo).add(-9, 'M'),
    },
    {
        label: '12 mo',
        getStartDate: (relativeTo) => moment(relativeTo).add(-12, 'M'),
    },
    {
        label: '15 mo',
        getStartDate: (relativeTo) => moment(relativeTo).add(-15, 'M'),
    },
    {
        label: '18 mo',
        getStartDate: (relativeTo) => moment(relativeTo).add(-18, 'M'),
    },
    {
        label: '24 mo',
        getStartDate: (relativeTo) => moment(relativeTo).add(-2, 'y'),
    },
    {
        label: '36 mo',
        getStartDate: (relativeTo) => moment(relativeTo).add(-3, 'y'),
    },
    {
        label: 'All Time',
        getStartDate: (relativeTo) => moment('2017-12-01'),
    },
]

export interface ITimePeriod {
    label: string
    date: moment.Moment
}

const getTimePeriods = (relativeTo: moment.Moment): ITimePeriod[] => {
    return timePeriodConfigurations.map((period) => {
        return {
            label: period.label,
            date: period.getStartDate(relativeTo),
        }
    })
}

const getMonths = (
    startingMonth: moment.Moment,
    endingMonth: moment.Moment,
): moment.Moment[] => {
    const months: moment.Moment[] = []
    let currentDate = moment(startingMonth)
    while (currentDate.isSameOrBefore(endingMonth, 'm')) {
        months.push(currentDate)
        currentDate = moment(currentDate).add(1, 'M')
    }
    return months
}

const getMonthlySnapshot = (
    balanceHistory: IBalanceSnapshot[],
    asOf: moment.Moment,
): IBalanceSnapshot | undefined => {
    const snapshot = _.find(balanceHistory, (snapshot) => {
        return snapshot.month === asOf.month() + 1 && snapshot.year === asOf.year()
    })
    return snapshot
}

const getMonthlyBalance = (
    balanceHistory: IBalanceSnapshot[],
    asOf: moment.Moment,
): number => {
    const snapshot = getMonthlySnapshot(balanceHistory, asOf)
    if (!snapshot) {
        return 0
    }
    return snapshot.balance
}

const getMonthlyFinal = (
    balanceHistory: IBalanceSnapshot[],
    asOf: moment.Moment,
): boolean => {
    const snapshot = getMonthlySnapshot(balanceHistory, asOf)
    if (!snapshot) {
        return true
    }
    return snapshot.final || false
}

const getMonthlyContributions = (
    balanceHistory: IBalanceSnapshot[],
    asOf: moment.Moment,
): number => {
    const snapshot = getMonthlySnapshot(balanceHistory, asOf)
    if (!snapshot) {
        return 0
    }
    return snapshot.contribution || 0
}

const shouldShowGainLoss = (accountHistory: IAccountHistory, config: IConfig) => {
    let shouldShow =
        accountHistory.account.returnType === ReturnCalculationType.MoneyWeighted
    if (
        !shouldShow &&
        accountHistory.account.type === AccountType.Cash &&
        !config.noGainLossOnCash
    ) {
        shouldShow = true
    }
    if (
        !shouldShow &&
        accountHistory.account.category === AccountCategory.Liability &&
        !config.noGainLossOnLiabilities
    ) {
        shouldShow = true
    }
    if (
        !shouldShow &&
        accountHistory.account.type === AccountType.Property &&
        !config.noGainLossOnProperty
    ) {
        shouldShow = true
    }
    return shouldShow
}

export {
    getMonthlyBalance,
    getMonthlyContributions,
    getMonthlyFinal,
    getMonthlySnapshot,
    getMonths,
    getTimePeriods,
    shouldShowGainLoss,
    timePeriodConfigurations,
}
