import _ from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
    getMonths,
    IConfig,
    IConfigOptions,
    ITimePeriodConfiguration,
    timePeriodConfigurations,
} from '../models'

const CONFIG_KEY = 'nwt:config'

const readConfigOptions = (
    defaultValue: IConfigOptions | null = null,
): IConfigOptions | null => {
    try {
        return JSON.parse(localStorage[CONFIG_KEY])
    } catch (e) {
        return defaultValue
    }
}

const writeConfigOptions = (config: IConfigOptions): IConfigOptions => {
    const persistableKeys = [
        'selectedTimePeriodLabels',
        // "selectedTimePeriodLabel",
        // "selectedAltTimePeriodLabel",
        'selectedAggregationKey',
        'showCents',
        'showZeroBalanceAccounts',
        'showSingularCategoryAccounts',
        'aggregatesOnly',
        'reverseMonthOrder',
        'visibleMonthCount',
        'noGainLossOnCash',
        'noGainLossOnProperty',
        'noGainLossOnLiabilities',
    ]
    const persistableConfig = _.pick(config, persistableKeys)
    localStorage[CONFIG_KEY] = JSON.stringify(persistableConfig)
    return config
}

interface IUseConfig {
    currentConfig: IConfig
    updateOptions: (updates: Partial<IConfigOptions>) => void
}

const useConfig = (): IUseConfig => {
    // const defaultTimePeriodConfiguration: ITimePeriodConfiguration =
    //     useMemo(() => {
    //         return (
    //             _.find(
    //                 timePeriodConfigurations,
    //                 (period) => !!period.default
    //             ) || timePeriodConfigurations[0]
    //         );
    //     }, [timePeriodConfigurations]);

    const initialConfigOptions: IConfigOptions = useMemo(() => {
        return {
            rateOfReturnLabel: 'ϕ',
            selectedTimePeriodLabels: ['1 mo'],
            // selectedTimePeriodLabel: '1 mo',
            // selectedAltTimePeriodLabel: '',
            selectedAggregationKey: 'type',
            showCents: false,
            showZeroBalanceAccounts: false,
            showSingularCategoryAccounts: false,
            aggregatesOnly: false,
            monthFormat: 'MMM YYYY',
            percentFormat: '0,0.00%',
            zeroValue: '-',
            zeroPercentValue: 'n/a',
            reverseMonthOrder: false,
            visibleMonthCount: 12,
            noGainLossOnCash: true,
            noGainLossOnLiabilities: true,
            noGainLossOnProperty: true,
            endingDate: moment(),
            ...readConfigOptions(),
        }
    }, [])

    const [configOptions, setConfigOptions] = useState<IConfigOptions>(() => {
        // console.log('initialConfigOptions', initialConfigOptions)
        return initialConfigOptions
    })

    const updateOptions = useCallback((updates: Partial<IConfigOptions>) => {
        setConfigOptions((prev) => {
            return {
                ...prev,
                ...updates,
            }
        })
    }, [])

    useEffect(() => {
        writeConfigOptions(configOptions)
        // console.log('wrote configOptions', configOptions)
    }, [configOptions])

    // values derived from config options
    const months: moment.Moment[] = useMemo(() => {
        const endingMonth = moment(configOptions.endingDate)
        const startingMonth = moment(endingMonth).add(
            (configOptions.visibleMonthCount - 1) * -1,
            'M',
        )
        let result = getMonths(startingMonth, endingMonth)
        if (!configOptions.reverseMonthOrder) {
            result = _.reverse(result)
        }
        return result
    }, [
        configOptions.visibleMonthCount,
        configOptions.reverseMonthOrder,
        configOptions.endingDate,
    ])

    const allDataMonths: moment.Moment[] = useMemo(() => {
        const endingDateMonth = moment(configOptions.endingDate)
        // todo derive startingDataMonth from data
        const startingDataMonth = moment('2017-12-01', 'YYYY-MM-DD')
        return getMonths(startingDataMonth, endingDateMonth)
    }, [configOptions.endingDate])

    const currencyFormat: string = useMemo(() => {
        return !configOptions.showCents ? '(0,000)' : '(0,000.00)'
    }, [configOptions.showCents])

    const selectedTimePeriodConfigurations: ITimePeriodConfiguration[] = useMemo(() => {
        console.log('selectedTimePeriodConfigurations changed')
        return _.compact(
            (configOptions.selectedTimePeriodLabels || []).map((selectedLabel) => {
                return _.find(
                    timePeriodConfigurations,
                    (period) => period.label === selectedLabel,
                )
            }),
        )
    }, [configOptions.selectedTimePeriodLabels])

    // const timePeriodConfiguration: ITimePeriodConfiguration = useMemo(() => {
    //     return (
    //         _.find(
    //             timePeriodConfigurations,
    //             (period) =>
    //                 period.label === configOptions.selectedTimePeriodLabel
    //         ) || defaultTimePeriodConfiguration
    //     );
    // }, [
    //     configOptions.selectedTimePeriodLabel,
    //     defaultTimePeriodConfiguration,
    //     timePeriodConfigurations,
    // ]);

    // const altTimePeriodConfiguration: ITimePeriodConfiguration | null =
    //     useMemo(() => {
    //         return (
    //             _.find(
    //                 timePeriodConfigurations,
    //                 (period) =>
    //                     period.label ===
    //                     configOptions.selectedAltTimePeriodLabel
    //             ) || null
    //         );
    //     }, [
    //         configOptions.selectedAltTimePeriodLabel,
    //         timePeriodConfigurations,
    //     ]);

    return {
        currentConfig: {
            ...configOptions,
            months,
            allDataMonths,
            currencyFormat,
            timePeriodConfigurations: selectedTimePeriodConfigurations,
            // timePeriodConfiguration,
            // altTimePeriodConfiguration,
        },
        updateOptions,
    }
}

export default useConfig
