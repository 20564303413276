import firebase from 'firebase/app'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { ICurrentHoldings } from '../models'

export const useHoldings = () => {
    const [holdings, setHoldings] = useState<ICurrentHoldings | null>(null)
    const [error, setError] = useState<any>(null)
    const [loading, setLoading] = useState(true)

    const fetchHoldings = () => {
        if (!firebase.auth().currentUser) {
            return
        }
        setLoading(true)
        const uid = firebase.auth().currentUser!.uid
        const holdingsDoc = firebase
            .firestore()
            .collection('holdings')
            .doc(uid)
            .collection('snapshots')
            .doc('current')

        holdingsDoc.onSnapshot(
            (snapshot) => {
                if (snapshot.exists && snapshot.data() && !_.isEmpty(snapshot.data())) {
                    console.log('fetched new holdings from firestore', snapshot.data())
                    setHoldings(snapshot.data() as ICurrentHoldings)
                    setLoading(false)
                }
            },
            (err) => {
                console.error('failed to retrieve holdings from firestore', err)
                setError(err)
                setLoading(false)
            },
        )
    }

    const refresh = () => {
        console.log('refreshing holdings...')
        setLoading(true)
        const refreshHoldings = firebase.functions().httpsCallable('refreshHoldings')
        return refreshHoldings().then(
            (result) => {
                const quotes = result.data as ICurrentHoldings
                setHoldings(quotes)
                setLoading(false)
            },
            (err) => {
                console.error('failed to retrieve holdings from API', err)
                setError(err)
                setLoading(false)
            },
        )
    }

    useEffect(() => {
        fetchHoldings()
    }, [])

    return {
        loading,
        holdings,
        error,
        refresh,
    }
}
